import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { ContentAlignType } from '~/@types/content-align'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextAlign } from '~/@types/text-align'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import { ImageProps } from '~/components/UI/Image'
import ImageDesktopMobile from '~/components/UI/ImageDesktopMobile'
import ResponsiveVideo, {
  ResponsiveVideoProps,
} from '~/components/UI/ResponsiveVideo'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import getContentAlign from '~/utils/get-content-align'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 8 / 24 }, { ratio: 22 / 24 }]

export interface EditoImageTitleTextProps {
  className?: string
  image?: ImageProps
  imageMobile?: ImageProps
  title?: RichTextBlocks
  subtitle?: RichTextBlocks
  text?: RichTextBlocks
  reversed?: boolean
  textAlign?: GlobalTextAlign
  contentAlign?: ContentAlignType
  desktopVideoUrl?: string
  mobileVideoUrl?: string
  desktopVideoRatio?: number
  mobileVideoRatio?: number
}

function EditoImageTitleText({
  className,
  subtitle,
  title,
  text,
  image,
  imageMobile,
  reversed,
  textAlign = GlobalTextAlign.AlignLeft,
  contentAlign = 'center',
  desktopVideoUrl,
  mobileVideoUrl,
  desktopVideoRatio,
  mobileVideoRatio,
}: EditoImageTitleTextProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title16_18Haffer,
    color: GlobalThemeColors.Black,
    textStyling: [GlobalTextStyling.UpperCase, textAlign],
  })

  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Title12Haffer,
    color: GlobalThemeColors.DoveGray,
    textStyling: [GlobalTextStyling.UpperCase, textAlign],
  })

  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.MineShaft,
    textStyling: textAlign,
  })

  const { position: slicePosition } = useSliceProvider()

  const isCenterAlign =
    contentAlign === 'center' ||
    contentAlign === 'center_left' ||
    contentAlign === 'center_right'

  const commonVideoProps: ResponsiveVideoProps = {
    ratio: css.ratio,
    isAutoplay: true,
    controls: false,
    disableControls: true,
    hasPlaysInline: true,
    isConstrained: true,
    isMuted: true,
    disableGrid: true,
    loop: true,
  }

  return (
    <div
      ref={ref}
      className={cx(css.EditoImageTitleText, className, gridStyle, {
        reversed,
        [css.center]: isCenterAlign,
        [css.top]: contentAlign === 'top_left',
        [css.bottom]: contentAlign === 'bottom_right',
      })}>
      {desktopVideoUrl && (
        <ResponsiveVideo
          className={css.asset}
          desktopVideoUrl={desktopVideoUrl}
          mobileVideoUrl={mobileVideoUrl}
          desktopVideoRatio={desktopVideoRatio}
          mobileVideoRatio={mobileVideoRatio}
          desktopPoster={image}
          mobilePoster={imageMobile}
          sizesFromBreakpoints={IMAGE_SIZES}
          {...commonVideoProps}
        />
      )}

      {!desktopVideoUrl && image && (
        <ImageDesktopMobile
          className={cx(css.asset)}
          desktopImage={image}
          mobileImage={imageMobile}
          sizesFromBreakpoints={IMAGE_SIZES}
          asPlaceholder
          layout="fill"
          objectFit="cover"
          ratio={css.ratio}
          priority={slicePosition === 1}
        />
      )}

      <div className={cx(css.content, getContentAlign(contentAlign))}>
        <RichText
          className={cx(css.subtitle, subtitleStyle)}
          render={subtitle}
        />
        <RichText className={cx(css.title, titleStyle)} render={title} />
        <RichText className={cx(css.text, textStyle)} render={text} />
      </div>
    </div>
  )
}

EditoImageTitleText.defaultProps = {
  reversed: false,
  textAlign: GlobalTextAlign.AlignLeft,
}

export default EditoImageTitleText
