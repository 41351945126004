import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalLayout } from '~/@types/layout'
import { GlobalTextAlign } from '~/@types/text-align'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'

import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import css from './styles.module.scss'

const cx = classnames.bind(css)

type ColumnEditoLayout = GlobalLayout.Centered | GlobalLayout.TwoColumns
export interface ColumnEditoProps {
  className?: string
  title?: RichTextBlocks
  text?: RichTextBlocks
  secondColumnText?: RichTextBlocks
  layout?: ColumnEditoLayout
  textAlign?: GlobalTextAlign
}

function ColumnEdito({
  className,
  title,
  text,
  secondColumnText,
  layout,
  textAlign,
}: ColumnEditoProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title14Haffer,
    color: GlobalThemeColors.Black,
    textStyling: [GlobalTextStyling.UpperCase, textAlign],
  })

  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.MineShaft,
    textStyling: [textAlign],
  })

  return (
    <div
      ref={ref}
      className={cx(css.ColumnEdito, className, gridStyle, css?.[layout])}>
      <div className={css.leftColumn}>
        <RichText className={cx(css.title, titleStyle)} render={title} />
        <RichText className={cx(css.text, textStyle)} render={text} />
      </div>
      {layout === GlobalLayout.TwoColumns && secondColumnText && (
        <RichText
          className={cx(css.rightColumn, textStyle)}
          render={secondColumnText}
        />
      )}
    </div>
  )
}

ColumnEdito.defaultProps = {
  layout: GlobalLayout.TwoColumns,
  textAlign: GlobalTextAlign.AlignLeft,
}

export default ColumnEdito
