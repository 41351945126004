import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextAlign } from '~/@types/text-align'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import Image, { ImageProps } from '~/components/UI/Image'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 11 / 24 }, { ratio: 22 / 24 }]

export interface EditoImageProps {
  className?: string
  image?: ImageProps
  title?: RichTextBlocks
  text?: RichTextBlocks
  reversed?: boolean
  textAlign?: GlobalTextAlign
}

function EditoImage({
  className,
  image,
  title,
  text,
  reversed,
  textAlign,
}: EditoImageProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title16_18Haffer,
    color: GlobalThemeColors.Black,
    textStyling: [GlobalTextStyling.UpperCase, textAlign],
  })
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.MineShaft,
    textStyling: textAlign,
  })

  const { position: slicePosition } = useSliceProvider()

  return (
    <div
      ref={ref}
      className={cx(css.EditoImage, className, gridStyle, { reversed })}>
      {image && (
        <Image
          className={cx(css.image)}
          ratio={css.ratio}
          sizesFromBreakpoints={IMAGE_SIZES}
          layout="fill"
          objectFit="cover"
          asPlaceholder
          priority={slicePosition === 1}
          {...image}
        />
      )}

      {(title || text) && (
        <div className={cx(css.content)}>
          <RichText
            render={title}
            className={cx(css.title, titleStyle)}
            ctaPreset={GlobalTextPreset.Cta12Haffer}
          />
          <RichText
            render={text}
            className={cx(css.text, textStyle)}
            ctaPreset={GlobalTextPreset.Cta12Haffer}
          />
        </div>
      )}
    </div>
  )
}

EditoImage.defaultProps = {
  reversed: false,
  textAlign: GlobalTextAlign.AlignCenter,
}

export default EditoImage
