import classnames from 'classnames'
import { useTranslate } from 'react-polyglot'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { useStyle } from '~/providers/StyleProvider'

import { GiftCardData } from '~/hooks/useGiftCardBalanceMutation'

import { GIFT_CARD } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface GiftCardResultProps {
  className?: string
  isDisabled?: boolean
  giftCard?: GiftCardData
}

function GiftCardResult({
  className,
  isDisabled,
  giftCard,
}: GiftCardResultProps) {
  const t = useTranslate()
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Title12Haffer,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div className={cx(css.GiftCardResult, className)}>
      {isDisabled && !giftCard && (
        <p className={cx(css.disabledText, textStyle)}>
          {t(GIFT_CARD.DISABLED)}
        </p>
      )}
      {!isDisabled && !giftCard && (
        <p className={cx(css.disabledText, textStyle)}>
          {t(GIFT_CARD.NOT_FOUND)}
        </p>
      )}

      {!isDisabled && giftCard && (
        <>
          {giftCard.cardNumber && (
            <p className={cx(css.resultLabel, textStyle)}>
              {t(GIFT_CARD.CARD_NUMBER)}:
              <span className={cx(css.resultValue, textStyle)}>
                &nbsp;{giftCard.cardNumber}
              </span>
            </p>
          )}
          {giftCard.expiresDate && (
            <p className={cx(css.resultLabel, textStyle)}>
              {t(GIFT_CARD.EXPIRES_ON)}:
              <span className={cx(css.resultValue, textStyle)}>
                &nbsp;{giftCard.expiresDate}
              </span>
            </p>
          )}
          {giftCard.balance && (
            <p className={cx(css.resultLabel, textStyle)}>
              {t(GIFT_CARD.AMOUNT)}:
              <span className={cx(css.resultValue, textStyle)}>
                &nbsp;{giftCard.balance}
              </span>
            </p>
          )}
        </>
      )}
    </div>
  )
}

export default GiftCardResult
