import classnames from 'classnames/bind'
import { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalBreakpoints } from '~/@types/breakpoints'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import {
  GridSliderMultipleLayout,
  GridSliderMultipleLayoutProps,
} from '~/components/Abstracts/GridSliderMultipleLayout'
import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import GridLookBookImagesZoom, {
  useGridLookBookImagesZooom,
} from '~/components/UI/GridLookBookImagesZoom'
import Image, { ImageProps, Sizes } from '~/components/UI/Image'
import SliderRangeSteps from '~/components/UI/SliderRangeSteps'
import SquaredCta from '~/components/UI/SquaredCta'

import { useStyle } from '~/providers/StyleProvider'

import useBreakpoint from '~/hooks/useBreakpoint'
import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import { GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title22_30Haffer,
  [GlobalTextSize.Small]: GlobalTextPreset.Title16_18Haffer,
}

const SIZES = {
  DEFAULT: [{ ratio: 24 / 24 }],
  MOBILE: [[{ ratio: 22 / 24 }], [{ ratio: 11 / 24 }], [{ ratio: 6 / 24 }]],
  DESKTOP: [[{ ratio: 11 / 24 }], [{ ratio: 6 / 24 }], [{ ratio: 3 / 24 }]],
}
const renderSizes = (step, isMobile): Sizes => {
  return (
    (isMobile ? SIZES?.MOBILE?.[step] : SIZES?.DESKTOP?.[step]) ?? SIZES.DEFAULT
  )
}

export type GridLookbookSliderMultipleLayoutProps =
  GridSliderMultipleLayoutProps & {
    title?: RichTextBlocks
    titleSize?: 'big' | 'small'
    images?: ImageProps[]
  }

const MAX_ITEMS = {
  DESKTOP: [6, 8, 8],
  MOBILE: [6, 6, 8],
}

function GridLookbookSliderMultipleLayout({
  title,
  titleSize,
  images,
}: GridLookbookSliderMultipleLayoutProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[titleSize],
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const isMobile = useBreakpoint(GlobalBreakpoints.MD)

  const [loadMore, setLoadMore] = useState(1)
  const [step, setStep] = useState(0)

  const productImagesZoom = useGridLookBookImagesZooom()

  const maxItems = isMobile
    ? MAX_ITEMS.MOBILE?.[step]
    : MAX_ITEMS.DESKTOP?.[step]

  const currentNbItems = maxItems * loadMore
  const processedImages = [...images]?.slice(0, currentNbItems)

  const t = useTranslate()
  return (
    <div ref={ref} className={cx(css.GridLookbookSliderMultipleLayout)}>
      <div className={gridStyle}>
        <div className={css.topContent}>
          <RichText className={cx(css.title, titleStyle)} render={title} />
          <SliderRangeSteps
            steps={3}
            defaultStep={1}
            className={css.SliderRangeSteps}
            setStep={setStep}
            springConfig={{ friction: 5 }}
          />
        </div>
      </div>
      <div className={css.content}>
        <GridSliderMultipleLayout step={step} customCss={css}>
          {({ step }) => {
            const SIZES = renderSizes(step, isMobile)

            return processedImages?.map(
              (image, index) =>
                image && (
                  <Image
                    onClick={() => {
                      productImagesZoom.setImageIndex(index)
                      productImagesZoom?.openZoom()
                    }}
                    layout="fill"
                    objectFit="cover"
                    ratio={css.ratio}
                    sizesFromBreakpoints={SIZES}
                    key={`image_${index}`}
                    asPlaceholder
                    {...image}
                  />
                ),
            )
          }}
        </GridSliderMultipleLayout>
        {images?.length > currentNbItems && (
          <SquaredCta
            theme={GlobalThemeColors.White}
            onClick={() => setLoadMore(loadMore + 1)}
            className={css.loadMore}>
            {t(GENERAL.LOAD_MORE)}
          </SquaredCta>
        )}
      </div>

      <GridLookBookImagesZoom
        {...productImagesZoom}
        onPreviousClick={() =>
          productImagesZoom?.imageIndex - 1 >= 0 &&
          productImagesZoom?.setImageIndex(productImagesZoom?.imageIndex - 1)
        }
        onNextClick={() =>
          productImagesZoom?.imageIndex + 1 <= processedImages?.length - 1 &&
          productImagesZoom?.setImageIndex(productImagesZoom?.imageIndex + 1)
        }
        images={processedImages}
      />
    </div>
  )
}

export default GridLookbookSliderMultipleLayout
