import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import { ArrowLeftIcon, ArrowRightIcon } from '~/components/Icons'

import { useStyle } from '~/providers/StyleProvider'

import { GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface SliderNavigationProps {
  className?: string
  onPrev(): void
  onNext(): void
  index: number
  maxIndex: number
  isPrevVisible?: boolean
  isNextVisible?: boolean
  disableArrows?: boolean
}

function SliderNavigation({
  className,
  onNext,
  onPrev,
  index,
  maxIndex,
  isPrevVisible,
  isNextVisible,
  disableArrows = false,
}: SliderNavigationProps) {
  const t = useTranslate()

  const indexStyle = useStyle({
    textPreset: GlobalTextPreset.Label11_12Haffer,
    color: GlobalThemeColors.MineShaft,
  })

  return (
    <div className={cx(css.SliderNavigation, className)}>
      {!disableArrows && (
        <button
          className={cx(css.button, css.prev, { isVisible: isPrevVisible })}
          onClick={onPrev}
          aria-label={t(GENERAL.SLIDER_BUTTON_LEFT)}>
          <ArrowLeftIcon className={cx(css.icon)} />
        </button>
      )}

      <span className={cx(css.index, indexStyle)}>
        {index} / {maxIndex}
      </span>

      {!disableArrows && (
        <button
          className={cx(css.button, css.next, { isVisible: isNextVisible })}
          onClick={onNext}
          aria-label={t(GENERAL.SLIDER_BUTTON_RIGHT)}>
          <ArrowRightIcon className={cx(css.icon)} />
        </button>
      )}
    </div>
  )
}

export default SliderNavigation
