import classnames from 'classnames/bind'
import { PropsWithClassName } from '~/@types/generic'

import DiptychOrTriptychItem, {
  DiptychOrTriptychItemProps,
} from '~/components/Slices/DiptychOrTriptych/DiptychOrTriptychItem'

import { useSliceProvider } from '~/providers/SliceProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type DiptychOrTriptychProps = PropsWithClassName<{
  items: DiptychOrTriptychItemProps[]
}>

function DiptychOrTriptych({ className, items }: DiptychOrTriptychProps) {
  const { position: slicePosition } = useSliceProvider()
  const itemsLength = items?.length

  if (!items || itemsLength === 1) {
    return null
  }

  return (
    <section className={cx(className, css.DiptychOrTriptych)}>
      {items?.map((item, index) => {
        return (
          <DiptychOrTriptychItem
            key={`diptych-or-triptych-${index}`}
            className={cx(css.item, {
              [css.diptych]: itemsLength === 2,
              [css.triptych]: itemsLength === 3,
            })}
            priority={slicePosition === 1}
            {...item}
          />
        )
      })}
    </section>
  )
}

export default DiptychOrTriptych
