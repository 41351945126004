import classnames from 'classnames/bind'
import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalInputPreset } from '~/@types/input-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { GYGIA_TAGS_KEYS } from '~/lib/shopify-tags'

import { useInputPreset } from '~/components/Abstracts/Form/Input/hooks'
import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import Checkbox from '~/components/Form/Checkbox'
import Input from '~/components/Form/Input'
import SquaredCta from '~/components/UI/SquaredCta'

import RecaptchaHandler from '~/providers/RecaptchaProvider/RecaptchaHandler'
import { useStyle } from '~/providers/StyleProvider'

import useShopifyNewsletterMutation from '~/hooks/account/useShopifyNewsletterMutation'
import useFormWithMutation from '~/hooks/useFormWithMutation'

import { CTA, FORM } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface FormEventProps {
  className?: string
  title?: string
  text?: RichTextBlocks
  checkboxLabel?: string
  legals?: RichTextBlocks
  tag: GYGIA_TAGS_KEYS
  campaign?: string
  setCanHavePanel?(canHave: boolean): void
  hasClose?: boolean
  onFocus?: React.FocusEventHandler<any>
  onBlur?: React.FocusEventHandler<any>
  onSuccess?(): void
}

function FormEvent({
  className,
  title,
  text,
  tag,
  checkboxLabel,
  legals,
  hasClose,
  setCanHavePanel,
  onFocus,
  onBlur,
  onSuccess,
  campaign,
}: FormEventProps) {
  const [result, setResult] = useState(false)

  const t = useTranslate()
  const { mutate: newsletterMutation, isLoading } =
    useShopifyNewsletterMutation(
      {
        onSuccess: () => {
          setResult(t(FORM.NEWSLETTER_SUCCESS))
          onSuccess?.()
        },
      },
      (...params) => form.setError(...params),
    )

  const { form, onSubmit } = useFormWithMutation(
    newsletterMutation,
    null,
    'newsletter',
  )

  const props = useInputPreset(GlobalInputPreset.Email, {
    placeholder: t(FORM.YOUR_EMAIL).toUpperCase(),
    onChange: () => setResult(false),
    onFocus,
    onBlur,
  })

  const titleFormStyle = useStyle({
    textPreset: GlobalTextPreset.Title12Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const textFormStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.Black,
  })

  const resultStyle = useStyle({
    textPreset: GlobalTextPreset.Title14Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const formLegalsStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Haffer,
  })

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  return (
    <div className={cx(css.FormEvent, className, gridStyle)}>
      {title && (
        <div className={cx(css.titleForm, titleFormStyle)}>{title}</div>
      )}

      {text && (
        <RichText className={cx(css.textForm, textFormStyle)} render={text} />
      )}

      <RecaptchaHandler className={cx(css.form)}>
        <FormProvider {...form}>
          <form onSubmit={onSubmit}>
            {!result && (
              <>
                <Input
                  className={cx(css.input)}
                  textPreset={GlobalTextPreset.Input14Haffer}
                  inputClassName={css.inputClassName}
                  {...props}
                />
                {/* Gygia Tag */}
                <Input type="hidden" name="from" value={tag} />
                {/* Campaign Tag */}
                <Input type="hidden" name="campaign" value={campaign} />
                {checkboxLabel && (
                  <Checkbox
                    name="terms"
                    className={cx(css.checkbox)}
                    label={checkboxLabel}
                    required>
                    {legals && (
                      <RichText
                        className={cx(css.legalsForm, formLegalsStyle)}
                        ctaPreset={GlobalTextPreset.Cta12Haffer}
                        render={legals}
                        uppercaseLink={false}
                      />
                    )}
                  </Checkbox>
                )}
              </>
            )}

            {result && (
              <div className={cx(css.result, resultStyle)}>{result}</div>
            )}

            {result ? (
              hasClose ? (
                <SquaredCta
                  type="submit"
                  className={css.submit}
                  isLoading={isLoading}
                  onClick={() => setCanHavePanel(false)}>
                  {t(CTA.CLOSE)}
                </SquaredCta>
              ) : null
            ) : (
              <SquaredCta
                type="submit"
                className={css.submit}
                isLoading={isLoading}>
                {t(FORM.SUBSCRIBE)}
              </SquaredCta>
            )}
          </form>
        </FormProvider>
      </RecaptchaHandler>
    </div>
  )
}

FormEvent.defaultProps = {}

export default FormEvent
