import classnames from 'classnames/bind'
import { useCallback } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextAlign } from '~/@types/text-align'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import { CtaProps } from '~/components/UI/Cta'
import InlineCta from '~/components/UI/InlineCta'
import SquaredCta from '~/components/UI/SquaredCta'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export enum CtaType {
  Inline = 'inline',
  Squared = 'squared',
}
export interface ImmersiveEditoTitleTextCtaProps {
  className?: string
  textAlign?: 'column' | GlobalTextAlign.AlignCenter
  ctaAlign?: 'under-title' | 'under-text'
  title?: RichTextBlocks
  text?: RichTextBlocks
  ctaType?: CtaType
  cta?: CtaProps
}

function ImmersiveEditoTitleTextCta({
  className,
  textAlign = 'column',
  ctaAlign = 'under-title',
  title,
  text,
  cta,
  ctaType,
}: ImmersiveEditoTitleTextCtaProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title14_18Haffer,
    textStyling: GlobalTextStyling.UpperCase,
    color: GlobalThemeColors.Black,
  })

  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.MineShaft,
  })

  const renderCta = useCallback(
    ({ className }) => {
      if (ctaType === CtaType.Inline) {
        return (
          <InlineCta
            {...cta}
            textStyling={GlobalTextStyling.UpperCase}
            textPreset={GlobalTextPreset.Cta12Haffer}
            theme={GlobalThemeColors.Black}
            className={cx(className, { isInline: true })}
          />
        )
      } else if (ctaType === CtaType.Squared) {
        return (
          <SquaredCta
            {...cta}
            theme={GlobalThemeColors.White}
            className={cx(className, { isSquared: true })}
          />
        )
      }
    },
    [ctaType],
  )

  return (
    <div className={cx(css.ImmersiveEditoTitleTextCta, className)}>
      <div className={gridStyle}>
        <div
          className={cx(css.left, {
            isColumn: textAlign === 'column',
            isCentered: textAlign === GlobalTextAlign.AlignCenter,
          })}>
          <RichText render={title} className={cx(titleStyle, css.title)} />
          {cta &&
            ctaAlign === 'under-title' &&
            renderCta({ className: css.cta })}
        </div>
        <div
          className={cx(css.right, {
            isColumn: textAlign === 'column',
            isCentered: textAlign === GlobalTextAlign.AlignCenter,
          })}>
          <RichText render={text} className={cx(textStyle, css.text)} />
          {cta &&
            ctaAlign === 'under-text' &&
            renderCta({ className: css.cta })}
        </div>
      </div>
    </div>
  )
}

export default ImmersiveEditoTitleTextCta
