import { Nullish } from '~/@types/shared'

import Video, { VideoProps } from '~/components/Slices/Video'
import { ImageProps } from '~/components/UI/Image'

import useBreakpoint from '~/hooks/useBreakpoint'

export interface ResponsiveVideoProps extends Omit<VideoProps, 'src'> {
  className?: string
  videoClassName?: string
  videoPlayerClassName?: string
  mockClassName?: string
  themeProductButton?: Nullish<'dark' | 'light' | 'none'>
  desktopVideoName?: Nullish<string>
  desktopVideoUrl?: Nullish<string>
  desktopVideoRatio?: number
  mobileVideoName?: Nullish<string>
  mobileVideoUrl?: Nullish<string>
  mobileVideoRatio?: number
  desktopPoster?: ImageProps
  mobilePoster?: ImageProps
  desktopVideoPoster?: Nullish<string>
  mobileVideoPoster?: Nullish<string>
}

function ResponsiveVideo({
  className,
  videoClassName,
  videoPlayerClassName,
  mockClassName,
  desktopVideoName,
  desktopVideoUrl,
  desktopVideoRatio,
  mobileVideoName,
  mobileVideoUrl,
  mobileVideoRatio,
  desktopPoster,
  mobilePoster,
  desktopVideoPoster,
  mobileVideoPoster,
  loadingSpinnerTheme,
  ratio,
  ...rest
}: ResponsiveVideoProps) {
  const isMobile = useBreakpoint('md')

  const videoUrl = isMobile
    ? mobileVideoUrl ?? desktopVideoUrl
    : desktopVideoUrl

  const videoName = isMobile
    ? mobileVideoName ?? desktopVideoName
    : desktopVideoName

  const videoRatio = isMobile
    ? mobileVideoRatio ?? desktopVideoRatio
    : desktopVideoRatio

  const posterImage = isMobile ? mobilePoster ?? desktopPoster : desktopPoster

  if (!videoUrl) {
    return null
  }

  return (
    <Video
      key={videoName}
      className={className}
      videoClassName={videoClassName}
      videoPlayerClassName={videoPlayerClassName}
      mockClassName={mockClassName}
      src={videoUrl}
      imagePoster={posterImage}
      videoPoster={isMobile ? mobileVideoPoster : desktopVideoPoster}
      videoRatio={videoRatio}
      ratio={ratio}
      {...rest}
    />
  )
}

export default ResponsiveVideo
