import useLocale from '~/hooks/useLocale'
import { DEFAULT_CMS_LANG } from '~/lib/constants'

import { getCMSLang } from '~/utils/locales'

function useCMSLang(): string {
  const locale = useLocale()
  return getCMSLang(locale) ?? DEFAULT_CMS_LANG
}

export default useCMSLang
