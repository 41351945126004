import { GlobalTextAlign } from '~/@types/text-align';

function getTextAlign(align: GlobalTextAlign) {
  const aligns = {
    'align-left': 'left',
    'align-center': 'center',
    'align-right': 'right',
  };

  return aligns[align] || 'center';
}
export default getTextAlign;