import { useEffect, useState } from 'react'
import { GlobalBreakpoints } from '~/@types/breakpoints'

import GridLookBookFullScreenImage, {
  GridLookBookFullScreenImageProps,
} from '~/components/UI/GridLookBookImagesZoom/GridLookBookFullScreenImage'
import GridLookBookImageGallery from '~/components/UI/GridLookBookImagesZoom/GridLookBookImageGallery'
import { ImageProps } from '~/components/UI/Image'

import useBreakpoint from '~/hooks/useBreakpoint'

export interface GridLookBookImagesZoomProps {
  className?: string
  images?: ImageProps[]
  isZoomed?: boolean
  closeZoom?(): void
  openZoom?(): void
  imageIndex?: number
  setImageIndex?(number): void
  onPreviousClick?: GridLookBookFullScreenImageProps['onPreviousClick']
  onNextClick?: GridLookBookFullScreenImageProps['onNextClick']
}

export function useGridLookBookImagesZooom(): GridLookBookImagesZoomProps {
  const [imageIndex, setImageIndex] = useState(0)
  const [isZoomed, setIsZoomed] = useState(false)
  return {
    isZoomed,
    closeZoom: () => setIsZoomed(false),
    openZoom: () => setIsZoomed(true),
    imageIndex,
    setImageIndex,
  }
}
function GridLookBookImagesZoom({
  images,
  isZoomed,
  closeZoom,
  imageIndex,
  setImageIndex,
  onPreviousClick,
  onNextClick,
}: GridLookBookImagesZoomProps) {
  const isMobile = useBreakpoint(GlobalBreakpoints.MD)

  useEffect(() => {
    if (!isZoomed) {
      closeZoom?.()
    }
  }, [isZoomed, closeZoom])

  return isZoomed ? (
    <>
      {!isMobile ? (
        <GridLookBookFullScreenImage
          key={`fullScreenImage${images?.[imageIndex]?.src}`}
          onExitFullscreen={closeZoom}
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
          imageProps={images?.[imageIndex]}
        />
      ) : (
        <GridLookBookImageGallery
          setImageIndex={setImageIndex}
          imageIndex={imageIndex}
          onExitFullscreen={closeZoom}
          imagesProps={images}
        />
      )}
    </>
  ) : null
}

GridLookBookImagesZoom.defaultProps = {}

export default GridLookBookImagesZoom
