import classnames from 'classnames/bind'
import { PropsWithClassName } from '~/@types/generic'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import WrapperWithLink from '~/components/Abstracts/WrapperWithLink'
import type { ImageProps } from '~/components/UI/Image'
import ImageDesktopMobile from '~/components/UI/ImageDesktopMobile'
import InlineCta, { InlineCtaProps } from '~/components/UI/InlineCta'
import { LinkProps } from '~/components/UI/Link'
import ResponsiveVideo, {
  ResponsiveVideoProps,
} from '~/components/UI/ResponsiveVideo'

import { useStyle } from '~/providers/StyleProvider'

import { capitalize } from '~/utils/capitalize'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 8 / 24 }, { ratio: 22 / 24 }]

type contentColor = 'light' | 'dark'

export type EditoPushItemProps = PropsWithClassName<{
  editoType: number
  priority?: boolean
  contentPosition: 'inside' | 'outside'
  title: RichTextBlocks
  link: LinkProps
  desktopImage: ImageProps
  mobileImage: ImageProps
  cta: InlineCtaProps
  desktopContentColor: contentColor
  mobileContentColor: contentColor
  desktopVideoUrl?: string
  mobileVideoUrl?: string
  desktopVideoRatio?: number
  mobileVideoRatio?: number
}>

const EDITO_PUSH_TYPES = ['none', 'diptych', 'triptych', 'quadriptych']

function EditoItemPush({
  className,
  editoType,
  contentPosition,
  title,
  link,
  cta,
  desktopImage,
  mobileImage,
  desktopContentColor,
  mobileContentColor,
  desktopVideoUrl,
  mobileVideoUrl,
  desktopVideoRatio,
  mobileVideoRatio,
  priority = false,
}: EditoPushItemProps) {
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title16_18Haffer,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const ctaStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12Haffer,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const commonVideoProps: ResponsiveVideoProps = {
    ratio: css.ratio,
    isAutoplay: true,
    controls: false,
    disableControls: true,
    hasPlaysInline: true,
    isConstrained: true,
    isMuted: true,
    disableGrid: true,
    loop: true,
  }

  return (
    <div className={cx(className, css.EditoPushItem)}>
      <div className={css.wrapper}>
        <WrapperWithLink {...link}>
          {desktopVideoUrl && (
            <ResponsiveVideo
              className={cx(css[EDITO_PUSH_TYPES[editoType]])}
              desktopVideoUrl={desktopVideoUrl}
              mobileVideoUrl={mobileVideoUrl}
              desktopVideoRatio={desktopVideoRatio}
              mobileVideoRatio={mobileVideoRatio}
              desktopPoster={desktopImage}
              mobilePoster={mobileImage}
              sizesFromBreakpoints={IMAGE_SIZES}
              {...commonVideoProps}
            />
          )}

          {!desktopVideoUrl && desktopImage && (
            <ImageDesktopMobile
              desktopImage={desktopImage}
              mobileImage={mobileImage}
              className={cx(css[EDITO_PUSH_TYPES[editoType]])}
              sizesFromBreakpoints={IMAGE_SIZES}
              layout="fill"
              objectFit="cover"
              asPlaceholder
              ratio={css.ratio}
              draggable="false"
              priority={priority}
            />
          )}
        </WrapperWithLink>
        {(title || cta) && (
          <div
            className={cx(
              css.content,
              css[contentPosition],
              css[`desktopColor${capitalize(desktopContentColor)}`],
              css[`mobileColor${capitalize(mobileContentColor)}`],
            )}>
            {title && <RichText className={titleStyle} render={title} />}
            {cta && <InlineCta className={cx(css.cta, ctaStyle)} {...cta} />}
          </div>
        )}
      </div>
    </div>
  )
}

export default EditoItemPush
