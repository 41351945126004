import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import GridLookBookImagesZoom, {
  useGridLookBookImagesZooom,
} from '~/components/UI/GridLookBookImagesZoom'
import { ImageProps } from '~/components/UI/Image'
import ImageDesktopMobile from '~/components/UI/ImageDesktopMobile'
import ResponsiveVideo, {
  ResponsiveVideoProps,
} from '~/components/UI/ResponsiveVideo'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface GridLookbookImagesProps {
  className?: string
  leftImage?: ImageProps
  leftMobileImage?: ImageProps
  leftText?: RichTextBlocks
  rightImage?: ImageProps
  rightMobileImage?: ImageProps
  rightText?: RichTextBlocks

  leftDesktopVideoUrl?: string
  leftMobileVideoUrl?: string
  leftDesktopVideoRatio?: number
  leftMobileVideoRatio?: number

  rightDesktopVideoUrl?: string
  rightMobileVideoUrl?: string
  rightDesktopVideoRatio?: number
  rightMobileVideoRatio?: number
}

const IMAGE_SIZES = [{ ratio: 11 / 24 }]

function GridLookbookImages({
  className,
  leftImage,
  leftMobileImage,
  leftText,
  rightImage,
  rightMobileImage,
  rightText,
  leftDesktopVideoUrl,
  leftMobileVideoUrl,
  leftDesktopVideoRatio,
  leftMobileVideoRatio,
  rightDesktopVideoUrl,
  rightMobileVideoUrl,
  rightDesktopVideoRatio,
  rightMobileVideoRatio,
}: GridLookbookImagesProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.DoveGray,
  })

  const productImagesZoom = useGridLookBookImagesZooom()
  const images = [leftImage, rightImage]?.filter(Boolean)

  const { position: slicePosition } = useSliceProvider()

  const commonVideoProps: ResponsiveVideoProps = {
    ratio: css.ratio,
    isAutoplay: true,
    controls: false,
    disableControls: true,
    hasPlaysInline: true,
    isConstrained: true,
    isMuted: true,
    disableGrid: true,
    loop: true,
  }

  return (
    <div ref={ref} className={cx(css.GridLookbookImages, className, gridStyle)}>
      <div className={css.content}>
        <div className={cx(css.left, css.item)}>
          {leftDesktopVideoUrl && (
            <ResponsiveVideo
              className={css.asset}
              desktopVideoUrl={leftDesktopVideoUrl}
              mobileVideoUrl={leftMobileVideoUrl}
              desktopVideoRatio={leftDesktopVideoRatio}
              mobileVideoRatio={leftMobileVideoRatio}
              desktopPoster={leftImage}
              mobilePoster={leftMobileImage}
              sizesFromBreakpoints={IMAGE_SIZES}
              {...commonVideoProps}
            />
          )}

          {!leftDesktopVideoUrl && leftImage && (
            <ImageDesktopMobile
              className={cx(css.asset, css.zoom)}
              desktopImage={leftImage}
              mobileImage={leftMobileImage}
              sizesFromBreakpoints={IMAGE_SIZES}
              asPlaceholder
              layout="fill"
              objectFit="cover"
              ratio={css.ratio}
              priority={slicePosition === 1}
              onClick={() => {
                productImagesZoom.setImageIndex(0)
                productImagesZoom?.openZoom()
              }}
            />
          )}

          <RichText className={cx(css.text, textStyle)} render={leftText} />
        </div>
        <div className={cx(css.right, css.item)}>
          {rightDesktopVideoUrl && (
            <ResponsiveVideo
              className={css.asset}
              desktopVideoUrl={rightDesktopVideoUrl}
              mobileVideoUrl={rightMobileVideoUrl}
              desktopVideoRatio={rightDesktopVideoRatio}
              mobileVideoRatio={rightMobileVideoRatio}
              desktopPoster={rightImage}
              mobilePoster={rightMobileImage}
              sizesFromBreakpoints={IMAGE_SIZES}
              {...commonVideoProps}
            />
          )}
          {!rightDesktopVideoUrl && rightImage && (
            <ImageDesktopMobile
              className={cx(css.asset, css.zoom)}
              desktopImage={rightImage}
              mobileImage={rightMobileImage}
              sizesFromBreakpoints={IMAGE_SIZES}
              asPlaceholder
              layout="fill"
              objectFit="cover"
              ratio={css.ratio}
              priority={slicePosition === 1}
              onClick={() => {
                productImagesZoom.setImageIndex(1)
                productImagesZoom?.openZoom()
              }}
            />
          )}

          <RichText className={cx(css.text, textStyle)} render={rightText} />
        </div>
      </div>

      {images?.length > 0 && (
        <GridLookBookImagesZoom {...productImagesZoom} images={images} />
      )}
    </div>
  )
}

GridLookbookImages.defaultProps = {}

export default GridLookbookImages
