import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import Image, { ImageProps } from '~/components/UI/Image'
import Intensity, { IntensityProps } from '~/components/UI/Intensity'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 12 / 24 }, { ratio: 22 / 24 }]

interface LabelTextProps {
  label: RichTextBlocks
  text: RichTextBlocks
}

export interface EditoColumnProps {
  className?: string
  image?: ImageProps
  title?: RichTextBlocks
  intensity?: IntensityProps['intensity']
  description?: RichTextBlocks
  subtitle?: RichTextBlocks
  texts?: LabelTextProps[]
  reversed?: boolean
}

function LabelText({ label, text }: LabelTextProps) {
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Haffer,
    color: GlobalThemeColors.DoveGray,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <>
      <RichText className={cx(css.label, labelStyle)} render={label} />
      <RichText className={cx(css.text, textStyle)} render={text} />
    </>
  )
}

function Content({
  title,
  intensity,
  description,
  subtitle,
  texts,
  image,
  mobile = false,
}: Omit<EditoColumnProps, 'className' | 'reversed'> & {
  mobile?: boolean
}) {
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title16_18Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const descriptionStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.MineShaft,
  })

  const { position: slicePosition } = useSliceProvider()

  return (
    <div className={mobile ? css.hideOnLargeScreen : css.hideOnSmallScreen}>
      <div className={css.component}>
        <div className={cx(css.header, { content: mobile, mobile })}>
          <RichText className={cx(css.title, titleStyle)} render={title} />

          {intensity && (
            <Intensity
              visible
              className={cx(css.intensity)}
              intensity={intensity}
            />
          )}

          <RichText
            className={cx(css.description, descriptionStyle)}
            render={description}
          />
        </div>
        {mobile && image && (
          <Image
            className={cx(css.image)}
            ratio={css.ratio}
            sizesFromBreakpoints={IMAGE_SIZES}
            layout="fill"
            objectFit="cover"
            asPlaceholder
            priority={slicePosition === 1}
            {...image}
          />
        )}
        <div className={cx(css.footer, { content: mobile, mobile })}>
          <RichText
            className={cx(css.subtitle, titleStyle)}
            render={subtitle}
          />

          {texts && texts.length > 0 && (
            <div className={cx(css.texts)}>
              {texts.map((props, index) => (
                <LabelText {...props} key={`label_text_${index}`} />
              ))}
            </div>
          )}
        </div>
      </div>
      {!mobile && image && (
        <Image
          className={cx(css.image)}
          ratio={css.ratio}
          sizesFromBreakpoints={IMAGE_SIZES}
          layout="fill"
          objectFit="cover"
          asPlaceholder
          priority={slicePosition === 1}
          {...image}
        />
      )}
    </div>
  )
}

function EditoColumn({
  className,
  image,
  title,
  intensity,
  description,
  subtitle,
  texts,
  reversed,
}: EditoColumnProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const props = {
    title,
    intensity,
    description,
    subtitle,
    texts,
    image,
  }

  return (
    <div
      ref={ref}
      className={cx(css.EditoColumn, className, gridStyle, { reversed })}>
      <Content {...props} mobile />
      <Content {...props} />
    </div>
  )
}

EditoColumn.defaultProps = {
  reversed: false,
}

export default EditoColumn
