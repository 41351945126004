import classnames from 'classnames/bind'
import { Nullish, PropsWithClassName } from '~/@types/generic'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import WrapperWithLink from '~/components/Abstracts/WrapperWithLink'
import { ImageProps } from '~/components/UI/Image'
import ImageDesktopMobile from '~/components/UI/ImageDesktopMobile'
import InlineCta, { InlineCtaProps } from '~/components/UI/InlineCta'
import { LinkProps } from '~/components/UI/Link'
import ResponsiveVideo, {
  ResponsiveVideoProps,
} from '~/components/UI/ResponsiveVideo'

import { useStyle } from '~/providers/StyleProvider'

import { capitalize } from '~/utils/capitalize'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 24 / 24 }, { ratio: 12 / 24 }]

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title18_22Haffer,
  [GlobalTextSize.Small]: GlobalTextPreset.Title18Haffer,
}

type contentColors = 'dark' | 'light'

export type DiptychOrTriptychItemProps = PropsWithClassName<{
  priority?: boolean
  title: Nullish<RichTextBlocks>
  desktopImage: Nullish<ImageProps>
  mobileImage: Nullish<ImageProps>
  link: Nullish<LinkProps>
  cta: Nullish<InlineCtaProps>
  desktopContentColor: contentColors
  mobileContentColor: contentColors
  titleSize: GlobalTextSize
  desktopVideoUrl?: string
  mobileVideoUrl?: string
  desktopVideoRatio?: number
  mobileVideoRatio?: number
}>

function DiptychOrTriptychItem({
  className,
  title,
  desktopImage,
  mobileImage,
  desktopVideoUrl,
  mobileVideoUrl,
  desktopVideoRatio,
  mobileVideoRatio,
  link,
  cta,
  desktopContentColor,
  mobileContentColor,
  titleSize,
  priority = false,
}: DiptychOrTriptychItemProps) {
  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[titleSize],
    textStyling: GlobalTextStyling.UpperCase,
  })

  const ctaStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12Haffer,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const commonVideoProps: ResponsiveVideoProps = {
    ratio: css.ratio,
    isAutoplay: true,
    controls: false,
    disableControls: true,
    hasPlaysInline: true,
    isConstrained: true,
    isMuted: true,
    disableGrid: true,
    loop: true,
  }

  return (
    <WrapperWithLink
      className={cx(className, css.DiptychOrTriptychItem)}
      {...link}>
      {(title || cta) && (
        <div
          className={cx(
            css.content,
            css[`desktopColor${capitalize(desktopContentColor)}`],
            css[`mobileColor${capitalize(mobileContentColor)}`],
          )}>
          {title && <RichText className={cx(titleStyle)} render={title} />}
          {cta && <InlineCta {...cta} className={cx(css.cta, ctaStyle)} />}
        </div>
      )}

      {desktopVideoUrl && (
        <ResponsiveVideo
          desktopVideoUrl={desktopVideoUrl}
          mobileVideoUrl={mobileVideoUrl}
          desktopVideoRatio={desktopVideoRatio}
          mobileVideoRatio={mobileVideoRatio}
          desktopPoster={desktopImage}
          mobilePoster={mobileImage}
          sizesFromBreakpoints={IMAGE_SIZES}
          {...commonVideoProps}
        />
      )}

      {!desktopVideoUrl && desktopImage && (
        <ImageDesktopMobile
          desktopImage={desktopImage}
          mobileImage={mobileImage}
          sizesFromBreakpoints={IMAGE_SIZES}
          asPlaceholder
          layout="fill"
          objectFit="cover"
          ratio={css.ratio}
          priority={priority}
        />
      )}
    </WrapperWithLink>
  )
}

export default DiptychOrTriptychItem
