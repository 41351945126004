import classnames from 'classnames/bind'
import { PropsWithClassName } from '~/@types/generic'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText from '~/components/Abstracts/RichText'
import { HeroMainRevampedProps } from '~/components/Slices/HeroMain'
import { HeroMainText } from '~/components/Slices/HeroMain/HeroMainText'
import { HeroMainTextProps } from '~/components/Slices/HeroMain/types'

import { useStyle } from '~/providers/StyleProvider'

import getCSSThemeClassName from '~/utils/get-css-theme-classname'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title22Haffer,
  [GlobalTextSize.Small]: GlobalTextPreset.Title16_18Haffer,
}

export type HeroBottomTextProps = PropsWithClassName<
  Pick<HeroMainRevampedProps, 'titleSize' | 'titleTheme'> &
    HeroMainTextProps & {
      titleContainerClassName?: string
      textsContainerClassName?: string
    }
>

export function HeroBottomText({
  className,
  titleContainerClassName,
  textsContainerClassName,
  title,
  titleSize,
  titleTheme,
  isWithoutText = false,
  ...rest
}: HeroBottomTextProps) {
  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[titleSize],
    textStyling: GlobalTextStyling.UpperCase,
  })

  const titleColorClass = getCSSThemeClassName(css, 'theme', titleTheme)

  return (
    <>
      <div className={cx(css.HeroBottomText, className)}>
        <div className={cx(css.titleContainer, titleContainerClassName)}>
          <RichText
            render={title}
            className={cx(css.title, titleStyle, titleColorClass)}
          />
        </div>
      </div>

      {!isWithoutText && (
        <div className={cx(css.textsContainer, textsContainerClassName)}>
          <div className={cx(css.text)}>
            <HeroMainText {...rest} />
          </div>
        </div>
      )}
    </>
  )
}
