import classnames from 'classnames/bind'
import { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { ImageProps } from '~/components/Abstracts/Image'
import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import Input from '~/components/Form/Input'
import GiftCardResult from '~/components/Slices/GiftCardBalanceChecker/GiftCardResult'
import Image from '~/components/UI/Image'
import SquaredCta from '~/components/UI/SquaredCta'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useStyle } from '~/providers/StyleProvider'

import useFormWithMutation from '~/hooks/useFormWithMutation'
import useGiftCardBalanceMutation, {
  GiftCardData,
  GiftCardDataResponse,
} from '~/hooks/useGiftCardBalanceMutation'

import { GIFT_CARD } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface GiftCardBalanceCheckerProps {
  className?: string
  title?: RichTextBlocks
  subtitle?: RichTextBlocks
  image?: ImageProps
}

function GiftCardBalanceChecker({ className }: GiftCardBalanceCheckerProps) {
  const { global } = useGlobalData()
  const t = useTranslate()
  const [giftCardContent, setGiftCardContent] = useState<GiftCardData>(null)
  const [isGiftCardDisabled, setIsGiftCardDisabled] = useState<boolean>(false)
  const [hasData, setHasData] = useState<boolean>(false)

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title16_18Haffer,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Title11_12Haffer,
  })

  const { mutate: giftCardBalanceMutation, isLoading } =
    useGiftCardBalanceMutation(
      {
        onSuccess: (data: GiftCardDataResponse) => {
          if (data) {
            setHasData(true)
          }

          if (data?.isDisabled) {
            setIsGiftCardDisabled(true)
            setGiftCardContent(null)
          } else {
            setIsGiftCardDisabled(false)
            setGiftCardContent(data?.giftCard)
          }
        },
      },
      (...params) => form.setError(...params),
    )

  const { form, onSubmit } = useFormWithMutation(giftCardBalanceMutation, null)

  return (
    <div className={cx(css.GiftCardBalanceChecker, className)} id="gc_balance">
      <div className={gridStyle}>
        <div className={css.left}>
          {global?.giftCardSlice?.title && (
            <RichText
              className={cx(css.title, titleStyle)}
              render={global?.giftCardSlice?.title}
            />
          )}
          {hasData && (
            <GiftCardResult
              className={css.result}
              isDisabled={isGiftCardDisabled}
              giftCard={giftCardContent}
            />
          )}
          {global?.giftCardSlice?.subtitle && (
            <RichText
              className={cx(css.subtitle, subtitleStyle)}
              render={global?.giftCardSlice?.subtitle}
            />
          )}

          <FormProvider {...form}>
            <form className={css.checker} onSubmit={onSubmit}>
              <Input
                className={cx(css.inputWrapper)}
                textPreset={GlobalTextPreset.Input12Haffer}
                required
                type="text"
                name="giftCardNumber"
                inputClassName={css.input}
                minLength={4}
                maxLength={4}
                placeholder={t(GIFT_CARD.ENTER_CARD_NR)}
              />
              <SquaredCta
                isLoading={isLoading}
                type="submit"
                className={css.cta}>
                {t(GIFT_CARD.CHECK_BALANCE)}
              </SquaredCta>
            </form>
          </FormProvider>
        </div>
        <div className={css.right}>
          {global?.giftCardSlice?.image && (
            <Image className={css.image} {...global?.giftCardSlice?.image} />
          )}
        </div>
      </div>
    </div>
  )
}

export default GiftCardBalanceChecker
