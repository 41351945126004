import classnames from 'classnames/bind'
import { useMeasure } from 'react-use'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalOrientation } from '~/@types/orientation'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import WrapperWithLink from '~/components/Abstracts/WrapperWithLink'
import { ImageProps } from '~/components/UI/Image'
import ImageDesktopMobile from '~/components/UI/ImageDesktopMobile'
import { LinkProps } from '~/components/UI/Link'
import SquaredCta, { SquaredCtaProps } from '~/components/UI/SquaredCta'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useParallax, {
  useGetParallaxStyleFromMaxTranslateAndHeight,
} from '~/hooks/useParallax'
import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import { isRTFilled } from '~/utils/check-empty-string'
import getCSSThemeClassName from '~/utils/get-css-theme-classname'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = {
  [GlobalOrientation.PORTRAIT]: [
    { breakpoint: 'md', ratio: 22 / 24 },
    { ratio: 14 / 24 },
  ],
  [GlobalOrientation.LANDSCAPE]: [
    { breakpoint: 'md', ratio: 22 / 24 },
    { ratio: 14 / 24 },
  ],
  square: [{ breakpoint: 'md', ratio: 22 / 24 }, { ratio: 14 / 24 }],
}

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title22_30Haffer,
  [GlobalTextSize.Small]: GlobalTextPreset.Title22_16Haffer,
}

const MAX_TRANSLATE_Y = 100

export interface HeaderStoryProps {
  className?: string
  subtitle?: RichTextBlocks
  title?: RichTextBlocks
  titleSize?: GlobalTextSize
  isFullWidth?: boolean
  imageDimension: GlobalOrientation & { square: 'square' }
  image: ImageProps
  mobileImage: ImageProps
  link: LinkProps
  cta: SquaredCtaProps
}

function HeaderStory({
  className,
  subtitle,
  title,
  titleSize,
  isFullWidth,
  imageDimension,
  image,
  mobileImage,
  cta,
  link,
}: HeaderStoryProps) {
  const { position: slicePosition } = useSliceProvider()
  const { ref: trackingPromotionRef } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Haffer,
    color: GlobalThemeColors.DoveGray,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[titleSize],
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const titleSizeStyle = getCSSThemeClassName(css, 'titleSize', titleSize)

  const { y, ref } = useParallax({
    displacement: MAX_TRANSLATE_Y,
  })

  const [containerRef, { height }] = useMeasure()

  const parallaxStyle = useGetParallaxStyleFromMaxTranslateAndHeight({
    y,
    maxTranslateY: MAX_TRANSLATE_Y,
    height,
  })

  return (
    <div
      ref={trackingPromotionRef}
      className={cx(css.HeaderStory, className, gridStyle, titleSizeStyle, {
        hasImage: image && mobileImage,
        isFullWidth,
        hasSubtitle: isRTFilled(subtitle),
      })}>
      <RichText className={cx(css.title, titleStyle)} render={title} />
      <RichText
        className={cx(css.subtitle, subtitleStyle, {
          [css.subtitleWithCta]: Boolean(cta),
        })}
        render={subtitle}
      />
      {cta && (
        <div className={css.cta}>
          <SquaredCta {...cta} theme={GlobalThemeColors.White} />
        </div>
      )}

      {(image || mobileImage) && (
        <WrapperWithLink {...link} className={css.imageContainer}>
          <div ref={containerRef}>
            <div ref={ref} style={parallaxStyle}>
              <ImageDesktopMobile
                className={cx(css.image)}
                desktopImage={image}
                mobileImage={mobileImage}
                ratio={cx(css.ratio, css[imageDimension])}
                layout="fill"
                objectFit="cover"
                sizesFromBreakpoints={IMAGE_SIZES[imageDimension]}
                asPlaceholder
                priority={slicePosition === 1}
              />
            </div>
          </div>
        </WrapperWithLink>
      )}
    </div>
  )
}

HeaderStory.defaultProps = {
  titleSize: GlobalTextSize.Big,
}

export default HeaderStory
