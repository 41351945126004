import classnames from 'classnames/bind'
import React, { useEffect } from 'react'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { PARCEL_LAB_ID } from '~/lib/constants'

import { Spinner } from '@unlikelystudio/react-abstract-components'
import { useIsMountedState } from '@unlikelystudio/react-hooks'

import { useStyle } from '~/providers/StyleProvider'

import useCMSLang from '~/hooks/useCMSLang'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ParcelLabTrackingWidgetProps {
  className?: string
}

function ParcelLabTrackingWidget({ className }: ParcelLabTrackingWidgetProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const cmsLang = useCMSLang()

  const isMounted = useIsMountedState()

  useEffect(() => {
    if (isMounted) {
      const plTrackAndTraceStart = () => {
        // @ts-ignore
        window.parcelLabTrackAndTrace.initialize({
          borderRadius: '0px',
          buttonBackground: '#000',
          buttonColor: '#FFF',
          borderColor: '#d2d2d2',
          plUserId: `${PARCEL_LAB_ID}`,
          lang: `${cmsLang}`,
          show_searchForm: true,
          show_zipCodeInput: true,
        })
        const linkTag = document.createElement('link')
        linkTag.rel = 'stylesheet'
        linkTag.href = 'https://cdn.parcellab.com/css/v5/main.min.css'
        document.getElementsByTagName('head')[0].appendChild(linkTag)
      }

      const createScript = (callback) => {
        const script = document.createElement('script')
        script.async = true
        script.src = 'https://cdn.parcellab.com/js/v5/main.min.js'
        ;(
          document.getElementsByTagName('head')[0] ||
          document.getElementsByTagName('body')[0]
        ).appendChild(script)
        script.onload = callback
      }
      createScript(plTrackAndTraceStart)
    }
  }, [isMounted])

  return (
    <div className={cx(css.ParcelLabTrackingWidget, className, gridStyle)}>
      <div className={css.widgetInner}>
        <div className={css.widgetInjector} id="parcellab-track-and-trace">
          <Spinner className={css.loader}></Spinner>
        </div>
      </div>
    </div>
  )
}

ParcelLabTrackingWidget.defaultProps = {}

export default ParcelLabTrackingWidget
