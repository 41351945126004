import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import GridLookBookImagesZoom, {
  useGridLookBookImagesZooom,
} from '~/components/UI/GridLookBookImagesZoom'
import Image, { ImageProps } from '~/components/UI/Image'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 12 / 24 }, { ratio: 22 / 24 }]

export interface GridLookbookSingleImageProps {
  className?: string
  image?: ImageProps
  text?: RichTextBlocks
}

function GridLookbookSingleImage({
  className,
  image,
  text,
}: GridLookbookSingleImageProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.DoveGray,
  })

  const productImagesZoom = useGridLookBookImagesZooom()

  const { position: slicePosition } = useSliceProvider()

  return (
    <div
      ref={ref}
      className={cx(css.GridLookbookSingleImage, className, gridStyle)}>
      {image && (
        <Image
          className={css.image}
          layout="fill"
          objectFit="cover"
          asPlaceholder
          ratio={css.ratio}
          sizesFromBreakpoints={IMAGE_SIZES}
          onClick={() => {
            productImagesZoom.setImageIndex(0)
            productImagesZoom?.openZoom()
          }}
          priority={slicePosition === 1}
          {...image}
        />
      )}

      <RichText className={cx(css.text, textStyle)} render={text} />

      {image && (
        <GridLookBookImagesZoom {...productImagesZoom} images={[image]} />
      )}
    </div>
  )
}

GridLookbookSingleImage.defaultProps = {}

export default GridLookbookSingleImage
