import { ContentAlignType } from '~/@types/content-align';


function getContentAlign(contentAlign: ContentAlignType) {
  const aligns = {
    center: 'center',
    center_left: 'centerLeft',
    center_right: 'centerRight',
    top_left: 'topLeft',
    bottom_right: 'bottomRight',
  };

  return aligns[contentAlign] || 'center';
}
export default getContentAlign;