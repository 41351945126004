import classnames from 'classnames/bind'
import { useState } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextAlign } from '~/@types/text-align'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import ProductCard, { ProductCardProps } from '~/components/Slices/ProductCard'
import { ImageProps } from '~/components/UI/Image'
import ImageDesktopMobile from '~/components/UI/ImageDesktopMobile'
import ResponsiveVideo, {
  ResponsiveVideoProps,
} from '~/components/UI/ResponsiveVideo'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useBreakpoint from '~/hooks/useBreakpoint'
import useTrackingProductImpression from '~/hooks/useTrackingProductImpresion'

import getTextAlign from '~/utils/get-text-align'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = {
  IMAGE: [{ breakpoint: 'md', ratio: 14.5 / 24 }, { ratio: 24 / 24 }],
  CARD: [{ breakpoint: 'md', ratio: 7 / 24 }, { ratio: 22 / 24 }],
}

export interface PushProductsImagesProps {
  className?: string
  title?: RichTextBlocks
  description?: RichTextBlocks
  image?: ImageProps
  mobileImage?: ImageProps
  card?: ProductCardProps
  reversed?: boolean
  textAlign?: GlobalTextAlign
  desktopVideoUrl?: string
  mobileVideoUrl?: string
  desktopVideoRatio?: number
  mobileVideoRatio?: number
}

function PushProductsImages({
  className,
  title,
  description,
  image,
  mobileImage,
  card,
  reversed,
  desktopVideoUrl,
  mobileVideoUrl,
  desktopVideoRatio,
  mobileVideoRatio,
  textAlign = GlobalTextAlign.AlignLeft,
}: PushProductsImagesProps) {
  const isMobile = useBreakpoint('md')
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const [imagesHeight, setImagesHeight] = useState(0)

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title22Haffer,
    color: GlobalThemeColors.Black,
    textStyling: [GlobalTextStyling.UpperCase, textAlign],
  })
  const descriptionStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.MineShaft,
    textStyling: textAlign,
  })

  const { ref: trackingProductRef } = useTrackingProductImpression(card)

  const { position: slicePosition } = useSliceProvider()

  const commonVideoProps: ResponsiveVideoProps = {
    isAutoplay: true,
    controls: false,
    disableControls: true,
    hasPlaysInline: true,
    isConstrained: true,
    isMuted: true,
    disableGrid: true,
    loop: true,
  }

  return (
    <div
      ref={trackingProductRef}
      className={cx(css.PushProductsImages, className, gridStyle, {
        reversed,
      })}>
      <RichText
        className={cx(css.title, titleStyle, getTextAlign(textAlign))}
        render={title}
      />
      <RichText
        className={cx(
          css.description,
          descriptionStyle,
          getTextAlign(textAlign),
        )}
        render={description}
      />

      <div className={cx(css.container)}>
        <div
          className={css.imageContainer}
          style={{ maxHeight: !isMobile && imagesHeight }}>
          {desktopVideoUrl && (
            <ResponsiveVideo
              className={css.asset}
              desktopVideoUrl={desktopVideoUrl}
              mobileVideoUrl={mobileVideoUrl}
              desktopVideoRatio={desktopVideoRatio}
              mobileVideoRatio={mobileVideoRatio}
              desktopPoster={image}
              mobilePoster={mobileImage}
              sizesFromBreakpoints={IMAGE_SIZES.IMAGE}
              ratio={css.assetRatio}
              {...commonVideoProps}
            />
          )}

          {!desktopVideoUrl && image && (
            <ImageDesktopMobile
              className={cx(css.asset)}
              desktopImage={image}
              mobileImage={mobileImage}
              sizesFromBreakpoints={IMAGE_SIZES.IMAGE}
              asPlaceholder
              layout="fill"
              objectFit="cover"
              ratio={css.assetRatio}
              priority={slicePosition ? slicePosition === 1 : false}
            />
          )}
        </div>

        {card && (
          <ProductCard
            className={cx(css.card)}
            ratio={css.imageCard}
            sizesFromBreakpoints={IMAGE_SIZES.CARD}
            priority={slicePosition === 1}
            textsHeight={setImagesHeight}
            {...card}
          />
        )}
      </div>
    </div>
  )
}

PushProductsImages.defaultProps = {
  reversed: false,
  textAlign: GlobalTextAlign.AlignCenter,
}

export default PushProductsImages
