import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { PropsWithClassName } from '~/@types/generic'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextAlign } from '~/@types/text-align'
import { GlobalTextSize } from '~/@types/text-size'

import { HeroBottomText } from '~/components/Slices/HeroMain/HeroBottomText'
import { HeroMedia } from '~/components/Slices/HeroMain/HeroMedia'
import {
  HeroMainContent,
  MediaItemTypes,
  TitleLayout,
} from '~/components/Slices/HeroMain/types'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import getCSSThemeClassName from '~/utils/get-css-theme-classname'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type HeroMainRevampedProps = PropsWithClassName<{
  type: MediaItemTypes
  content: HeroMainContent
  titleLayout: TitleLayout
  titleTheme?: GlobalThemeColors.Black | GlobalThemeColors.White
  titleSize?: GlobalTextSize
  textAlign?: GlobalTextAlign
  isFirstSlice?: boolean
  contentPosition?: 'left' | 'center'
  isWithoutText?: boolean
}>

export default function HeroMain({
  className,
  content,
  type,
  titleLayout = 'title-on-image',
  titleSize = GlobalTextSize.Big,
  textAlign = GlobalTextAlign.AlignCenter,
  titleTheme = GlobalThemeColors.Black,
  isFirstSlice,
  isWithoutText = false,
}: HeroMainRevampedProps) {
  // Getting the slice position
  const { position: slicePosition } = useSliceProvider()

  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleSizeStyle = getCSSThemeClassName(css, 'titleSize', titleSize)
  const titleLayoutStyle = getCSSThemeClassName(css, 'titleLayout', titleLayout)
  const textAlignStyle = getCSSThemeClassName(css, 'textAlign', textAlign)

  // Getting the text content
  const { text, media } = content ?? {}

  return (
    <div
      ref={ref}
      className={cx(
        css.HeroMain,
        className,
        gridStyle,
        titleLayoutStyle,
        titleSizeStyle,
        textAlignStyle,
      )}>
      <HeroMedia
        className={css.media}
        type={type}
        hasSlicePriority={
          isFirstSlice ? true : slicePosition ? slicePosition === 1 : false
        }
        {...media}
      />
      {titleLayout === 'title-below-image-edito-right' ? (
        <div className={css.bottomContainer}>
          <HeroBottomText
            className={cx(css.heroText)}
            titleContainerClassName={cx(css.titleContainer)}
            textsContainerClassName={css.textsContainer}
            titleTheme={titleTheme}
            titleSize={titleSize}
            isWithoutText={isWithoutText}
            {...text}
          />
        </div>
      ) : (
        <HeroBottomText
          className={cx(css.heroText)}
          titleContainerClassName={cx(css.titleContainer)}
          textsContainerClassName={css.textsContainer}
          titleTheme={titleTheme}
          titleSize={titleSize}
          isWithoutText={isWithoutText}
          {...text}
        />
      )}
    </div>
  )
}
