import classnames from 'classnames/bind'
import { useEffect } from 'react'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { PARCEL_LAB_BASE_URL, PARCEL_LAB_ID } from '~/lib/constants'

import { useIsMountedState } from '@unlikelystudio/react-hooks'

import { useStyle } from '~/providers/StyleProvider'

import useLanguage from '~/hooks/useLanguage'
import useParcelLabCountryCode from '~/hooks/useParcelLabCountryCode'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ParcelLabReturnWidgetProps {
  className?: string
}

function ParcelLabReturnWidget({ className }: ParcelLabReturnWidgetProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const lang = useLanguage()
  const parcelLabCountryCode = useParcelLabCountryCode()

  const isMounted = useIsMountedState()

  useEffect(() => {
    if (isMounted && parcelLabCountryCode) {
      const createScript = () => {
        const script = document.createElement('script')
        script.async = true
        script.src = `${PARCEL_LAB_BASE_URL}/dist/returns-plugin.js`
        ;(
          document.getElementsByTagName('head')[0] ||
          document.getElementsByTagName('body')[0]
        ).appendChild(script)
      }

      const createStyles = () => {
        if (!document.getElementById('pl-returns-portal-styles')) {
          const styles = document.createElement('link')
          styles.id = 'pl-returns-portal-styles'
          styles.rel = 'stylesheet'
          styles.href = `${PARCEL_LAB_BASE_URL}/dist/returns-plugin.css`
          document.getElementsByTagName('head')[0].appendChild(styles)
        }
      }

      createScript()
      createStyles()
    }
  }, [isMounted])

  return parcelLabCountryCode ? (
    <div className={cx(css.ParcelLabReturnWidget, className, gridStyle)}>
      <div className={css.widgetInner}>
        <div
          className={css.widgetInjector}
          id="pl-returns-plugin"
          data-user={`${PARCEL_LAB_ID}`}
          data-lang-code={lang}
          data-country-code={parcelLabCountryCode}></div>
      </div>
    </div>
  ) : null
}

ParcelLabReturnWidget.defaultProps = {}

export default ParcelLabReturnWidget
