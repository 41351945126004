import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextAlign } from '~/@types/text-align'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import { VideoProps } from '~/components/Slices/Video'
import Image, { ImageProps } from '~/components/UI/Image'
import ResponsiveVideo from '~/components/UI/ResponsiveVideo'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 8 / 24 }, { ratio: 22 / 24 }]

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title22Haffer,
  [GlobalTextSize.Small]: GlobalTextPreset.Title16_18Haffer,
}

export interface EditoTitleImageTextProps {
  className?: string
  image?: ImageProps
  videoUrl?: VideoProps['src']
  videoUrlMobile?: VideoProps['src']
  videoRatio?: VideoProps['videoRatio']
  videoRatioMobile?: VideoProps['videoRatio']
  title?: RichTextBlocks
  subtitle?: RichTextBlocks
  text?: RichTextBlocks
  reversed?: boolean
  textAlign?: GlobalTextAlign
  titleSize?: GlobalTextSize
}

function EditoTitleImageText({
  className,
  image,
  title,
  subtitle,
  text,
  reversed,
  textAlign,
  titleSize,
  videoUrl,
  videoUrlMobile,
  videoRatio,
  videoRatioMobile,
}: EditoTitleImageTextProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[titleSize],
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Title12Haffer,
    color: GlobalThemeColors.Black,
    textStyling: [GlobalTextStyling.UpperCase, textAlign],
  })

  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.MineShaft,
    textStyling: textAlign,
  })

  const { position: slicePosition } = useSliceProvider()

  return (
    <div
      ref={ref}
      className={cx(css.EditoTitleImageText, className, gridStyle, {
        reversed,
      })}>
      <RichText
        className={cx(css.title, titleStyle, {
          isBig: titleSize === GlobalTextSize.Big,
        })}
        render={title}
      />

      {videoUrl ? (
        <ResponsiveVideo
          className={cx(css.item)}
          disableGrid={true}
          ratio={css.videoRatio}
          sizesFromBreakpoints={IMAGE_SIZES}
          desktopPoster={image}
          desktopVideoUrl={videoUrl}
          mobileVideoUrl={videoUrlMobile}
          desktopVideoRatio={videoRatio}
          mobileVideoRatio={videoRatioMobile}
        />
      ) : (
        image && (
          <Image
            className={cx(css.item)}
            ratio={css.imageRatio}
            sizesFromBreakpoints={IMAGE_SIZES}
            layout="fill"
            objectFit="cover"
            asPlaceholder
            priority={slicePosition === 1}
            {...image}
          />
        )
      )}

      <div className={cx(css.content)}>
        <RichText
          className={cx(css.subtitle, subtitleStyle)}
          render={subtitle}
        />
        <RichText
          className={cx(css.text, textStyle)}
          render={text}
          ctaPreset={GlobalTextPreset.Cta12Haffer}
          uppercaseLink={false}
        />
      </div>
    </div>
  )
}

EditoTitleImageText.defaultProps = {
  reversed: false,
  textAlign: GlobalTextAlign.AlignLeft,
  titleSize: GlobalTextSize.Big,
}

export default EditoTitleImageText
