import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import ProductCard, { ProductCardProps } from '~/components/Slices/ProductCard'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingProductImpression from '~/hooks/useTrackingProductImpresion'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = {
  GRID: [{ breakpoint: 'md', ratio: 310 / 1320 }, { ratio: 166 / 340 }],
}

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title22_30Haffer,
  [GlobalTextSize.Medium]: GlobalTextPreset.Title18_22Haffer,
  [GlobalTextSize.Small]: GlobalTextPreset.Title14_18Haffer,
}

export interface ProductsGridProps {
  className?: string
  title?: RichTextBlocks
  text?: RichTextBlocks
  products?: ProductCardProps[]
  priorities?: boolean[]
  layout?: GlobalTextSize
  titleTextStyling?: 'center'
  isCategoryPage?: boolean
}

function ProductsGrid({
  className,
  title,
  text,
  titleTextStyling,
  products,
  priorities,
  layout = GlobalTextSize.Small,
  isCategoryPage = false,
}: ProductsGridProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[layout],
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.MineShaft,
  })

  const { ref } = useTrackingProductImpression(products)

  const { position: slicePosition } = useSliceProvider()

  return products?.length > 0 ? (
    <div
      ref={ref}
      className={cx(css.ProductsGrid, className, gridStyle, {
        [css.hasMargins]: !isCategoryPage,
      })}>
      <RichText
        className={cx(css.title, titleStyle, css[titleTextStyling])}
        render={title}
      />
      <RichText className={cx(css.text, textStyle)} render={text} />

      <div className={css.content}>
        {products?.map((product, index) => {
          return (
            <ProductCard
              className={css.product}
              cardWidthClassName={css.cardWidth}
              key={`product_${index}`}
              sizesFromBreakpoints={IMAGE_SIZES.GRID}
              priority={priorities?.[index] ?? slicePosition === 1}
              loading={priorities?.[index]}
              {...product}
            />
          )
        })}
      </div>
    </div>
  ) : null
}

ProductsGrid.defaultProps = {}

export default ProductsGrid
