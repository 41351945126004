import classnames from 'classnames/bind'
import React from 'react'
import { useTranslate } from 'react-polyglot'

import {
  ImageGallery as ImageGalleryAbstract,
  ImageGalleryProps as ImageGalleryAbstractProps,
} from '@unlikelystudio/react-abstract-components'

import Image, { ImageProps } from '~/components/UI/Image'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface GridLookBookImageGalleryProps
  extends ImageGalleryAbstractProps {
  imagesProps?: ImageProps[]
}

export default function GridLookBookImageGallery({
  className,
  imagesProps,
  ...props
}: GridLookBookImageGalleryProps) {
  const t = useTranslate()

  return (
    <ImageGalleryAbstract
      classes={{
        ImageGallery: cx(className, css.ImageGallery),
        slider: css.slider,
        imageContainer: css.imageContainer,
        image: css.image,
        closeButton: cx(css.closeButton),
      }}
      buttonComponent={t('fullscreen_image.close')}
      images={imagesProps?.map((image, index) => (
        <Image
          className={css.image}
          key={index}
          layout="fill"
          objectFit="cover"
          asPlaceholder
          {...image}
        />
      ))}
      {...props}
    />
  )
}
