import classnames from 'classnames'
import React, { ReactNode, CSSProperties } from 'react'

export interface ChildrenProps {
  step: number
}
export interface GridSliderMultipleLayoutProps {
  className?: string
  children?:
    | JSX.Element
    | JSX.Element[]
    | ReactNode
    | ReactNode[]
    | ((
        props: ChildrenProps,
      ) => JSX.Element | JSX.Element[] | ReactNode | ReactNode[])
  step?: number
  customCss?: Record<string, any>
}

export function GridSliderMultipleLayout({
  className,
  children,
  customCss,
  step,
}: GridSliderMultipleLayoutProps) {
  const processedChildren =
    typeof children === 'function' ? children?.({ step }) : children

  const componentStyle: CSSProperties = {
    display: 'grid',
  }

  return (
    <div
      className={classnames(
        className,
        customCss?.GridSliderMultipleLayout,
        customCss?.[`step-${step}`],
      )}
      style={componentStyle}
    >
      {Array.isArray(processedChildren) ? (
        processedChildren?.map((child, index) =>
          React.cloneElement(child, {
            className: [
              customCss?.item ?? null,
              child?.props?.className ?? null,
            ]
              ?.filter((css) => css !== null)
              ?.join(' '),
            key: `item_${index}`,
          }),
        )
      ) : (
        <div>{processedChildren}</div>
      )}
    </div>
  )
}

GridSliderMultipleLayout.defaultProps = {
  step: 0,
}
