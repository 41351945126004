import classnames from 'classnames/bind'
import { useCallback, useRef } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalOrientation } from '~/@types/orientation'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import Slider from '@unlikelystudio/react-slider'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import WrapperWithLink from '~/components/Abstracts/WrapperWithLink'
import Image, { ImageProps } from '~/components/UI/Image'
import { LinkProps } from '~/components/UI/Link'
import SquaredCta, { SquaredCtaProps } from '~/components/UI/SquaredCta'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title22Haffer,
  [GlobalTextSize.Small]: GlobalTextPreset.Title12_14Haffer,
}

const IMAGE_RATIOS = {
  [GlobalOrientation.PORTRAIT]: css.portraitRatio,
  [GlobalOrientation.LANDSCAPE]: css.landscapeRatio,
}

const IMAGE_SIZES = {
  [GlobalOrientation.PORTRAIT]: [
    { breakpoint: 'md', ratio: 5 / 24 },
    { ratio: 12 / 24 },
  ],
  [GlobalOrientation.LANDSCAPE]: [
    { breakpoint: 'md', ratio: 7.5 / 24 },
    { ratio: 18 / 24 },
  ],
}

type ImmersiveEditoImagesSliderItemOrientation =
  | GlobalOrientation.PORTRAIT
  | GlobalOrientation.LANDSCAPE

interface ImmersiveEditoImagesSliderItemProps {
  orientation: ImmersiveEditoImagesSliderItemOrientation
  image: ImageProps
  itemLink?: LinkProps
}

export interface ImmersiveEditoImagesSliderProps {
  className?: string
  title?: RichTextBlocks
  titleSize?: GlobalTextSize
  link?: LinkProps
  cta?: SquaredCtaProps
  items?: ImmersiveEditoImagesSliderItemProps[]
}

function ImmersiveEditoImagesSlider({
  className,
  title,
  titleSize = GlobalTextSize.Small,
  cta,
  items,
}: ImmersiveEditoImagesSliderProps) {
  const customSliderRef = useRef()

  const setRef = useCallback(
    (node) => {
      customSliderRef.current = node
    },
    [customSliderRef],
  )

  const { position: slicePosition } = useSliceProvider()

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[titleSize],
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div ref={setRef} className={cx(css.ImmersiveEditoImagesSlider, className)}>
      {title && (
        <div className={cx(css.titleContainer, gridStyle)}>
          <RichText className={cx(css.title, titleStyle)} render={title} />
        </div>
      )}
      {items && items.length > 0 && (
        <div className={css.containerSlider}>
          <Slider
            className={css.slider}
            customSliderRef={customSliderRef}
            infinite={true}
            autoPlay={{ speed: -0.4 }}
            autoIsSlider={false}>
            {items?.map((item, index) => (
              <WrapperWithLink key={`item_${index}`} {...item.itemLink}>
                <Image
                  asPlaceholder
                  className={cx(css.image, css?.[item.orientation])}
                  ratio={IMAGE_RATIOS[item.orientation]}
                  sizesFromBreakpoints={IMAGE_SIZES[item.orientation]}
                  priority={slicePosition === 1}
                  {...item.image}
                />
              </WrapperWithLink>
            ))}
          </Slider>
        </div>
      )}
      {cta && (
        <div className={cx(css.ctaContainer, gridStyle)}>
          <div className={css.cta}>
            <SquaredCta {...cta} theme={GlobalThemeColors.White} />
          </div>
        </div>
      )}
    </div>
  )
}

export default ImmersiveEditoImagesSlider
