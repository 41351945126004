import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { PropsWithClassName } from '~/@types/generic'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText from '~/components/Abstracts/RichText'
import { HeroBottomTextProps } from '~/components/Slices/HeroMain/HeroBottomText'
import SquaredCta from '~/components/UI/SquaredCta'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

type HeroMainTextProps = PropsWithClassName<
  Pick<HeroBottomTextProps, 'subtitle' | 'description' | 'cta'>
>

export function HeroMainText({
  className,
  subtitle,
  description,
  cta,
}: HeroMainTextProps) {
  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Title14_18Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const descriptionStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.MineShaft,
  })
  if (!description) return null

  return (
    <div className={cx(css.HeroMainText, className)}>
      <RichText className={cx(css.subtitle, subtitleStyle)} render={subtitle} />
      <div className={cx(css.description, descriptionStyle)}>
        <RichText
          ctaPreset={GlobalTextPreset.Cta12Haffer}
          uppercaseLink={false}
          render={description}
        />
      </div>

      {cta && (
        <div className={cx(css.cta)}>
          <SquaredCta
            className={cx(css.ctaLabel)}
            {...cta}
            withBackground={false}
            theme={GlobalThemeColors.Black}
            textPreset={GlobalTextPreset.Cta12Haffer}
          />
        </div>
      )}
    </div>
  )
}
