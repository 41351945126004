import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'

import ArticleCard, { ArticleCardProps } from '~/components/Slices/ArticleCard'

import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 10 / 24 }, { ratio: 22 / 24 }]

export interface StoriesGridImageProps {
  className?: string
  item?: ArticleCardProps
}

function StoriesGridImage({ className, item }: StoriesGridImageProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  return (
    <div ref={ref} className={cx(css.StoriesGridImage, className, gridStyle)}>
      {item && (
        <ArticleCard
          className={cx(css.item)}
          ratio={css.ratio}
          sizesFromBreakpoints={IMAGE_SIZES}
          {...item}
        />
      )}
    </div>
  )
}

StoriesGridImage.defaultProps = {}

export default StoriesGridImage
