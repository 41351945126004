import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import { ImageProps } from '~/components/UI/Image'
import ImageDesktopMobile from '~/components/UI/ImageDesktopMobile'
import ResponsiveVideo, {
  ResponsiveVideoProps,
} from '~/components/UI/ResponsiveVideo'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ImmersiveEditoHeroProps {
  className?: string
  title: RichTextBlocks
  subtitle?: RichTextBlocks
  image: ImageProps
  mobileImage?: ImageProps
  desktopVideoUrl?: string
  mobileVideoUrl?: string
  desktopVideoRatio?: number
  mobileVideoRatio?: number
}

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 14 / 24 }, { ratio: 22 / 24 }]

function ImmersiveEditoHero({
  className,
  title,
  subtitle,
  image,
  mobileImage,
  desktopVideoUrl,
  mobileVideoUrl,
  desktopVideoRatio,
  mobileVideoRatio,
}: ImmersiveEditoHeroProps) {
  // Getting the slice position to set its correct priority
  const { position: slicePosition } = useSliceProvider()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title22_30Haffer,
    textStyling: GlobalTextStyling.UpperCase,
    color: GlobalThemeColors.White,
  })

  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    textStyling: GlobalTextStyling.UpperCase,
    color: GlobalThemeColors.White,
  })

  const commonVideoProps: ResponsiveVideoProps = {
    ratio: css.ratio,
    isAutoplay: true,
    controls: false,
    disableControls: true,
    hasPlaysInline: true,
    isConstrained: true,
    isMuted: true,
    disableGrid: true,
    loop: true,
  }

  return (
    <div className={cx(css.ImmersiveEditoHero, className)}>
      <div className={cx(gridStyle)}>
        <div className={css.top}>
          <RichText render={title} className={cx(titleStyle, css.title)} />
          {subtitle && (
            <RichText
              render={subtitle}
              className={cx(subtitleStyle, css.subtitle)}
            />
          )}
        </div>

        {desktopVideoUrl && (
          <ResponsiveVideo
            className={css.asset}
            desktopVideoUrl={desktopVideoUrl}
            mobileVideoUrl={mobileVideoUrl}
            desktopVideoRatio={desktopVideoRatio}
            mobileVideoRatio={mobileVideoRatio}
            desktopPoster={image}
            mobilePoster={mobileImage}
            sizesFromBreakpoints={IMAGE_SIZES}
            {...commonVideoProps}
          />
        )}

        {!desktopVideoUrl && image && (
          <ImageDesktopMobile
            className={cx(css.asset)}
            desktopImage={image}
            mobileImage={mobileImage}
            sizesFromBreakpoints={IMAGE_SIZES}
            asPlaceholder
            layout="fill"
            objectFit="cover"
            ratio={css.ratio}
            priority={slicePosition ? slicePosition === 1 : false}
          />
        )}
      </div>
    </div>
  )
}

export default ImmersiveEditoHero
