import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'

import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface AnnouncementProps {
  className?: string
  text?: RichTextBlocks
}

function Announcement({ className, text }: AnnouncementProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Title22_30Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div ref={ref} className={cx(css.Announcement, className, gridStyle)}>
      <RichText className={cx(css.text, textStyle)} render={text} />
    </div>
  )
}

Announcement.defaultProps = {}

export default Announcement
