import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import Image, { ImageProps } from '~/components/UI/Image'
import SquaredCta, { SquaredCtaProps } from '~/components/UI/SquaredCta'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import getCSSThemeClassName from '~/utils/get-css-theme-classname'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 8 / 24 }, { ratio: 18 / 24 }]

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title22Haffer,
  [GlobalTextSize.Small]: GlobalTextPreset.Title16_18Haffer,
}

export interface PushImageCoverProps {
  className?: string
  image?: ImageProps
  titleTop?: RichTextBlocks
  titleBottom?: RichTextBlocks
  cta?: SquaredCtaProps
  titleSize?: GlobalTextSize
}

function PushImageCover({
  className,
  image,
  titleTop,
  titleBottom,
  cta,
  titleSize,
}: PushImageCoverProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[titleSize],
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const titleSizeStyle = getCSSThemeClassName(css, 'titleSize', titleSize)

  const { position: slicePosition } = useSliceProvider()

  return (
    <div
      ref={ref}
      className={cx(css.PushImageCover, className, gridStyle, titleSizeStyle)}>
      {image && (
        <Image
          className={cx(css.image)}
          ratio={css.ratio}
          sizesFromBreakpoints={IMAGE_SIZES}
          layout="fill"
          objectFit="cover"
          asPlaceholder
          priority={slicePosition === 1}
          {...image}
        />
      )}

      <RichText
        className={cx(css.title, css.top, titleStyle)}
        render={titleTop}
      />

      <RichText
        className={cx(css.title, css.bottom, titleStyle)}
        render={titleBottom}
      />

      {cta && <SquaredCta className={cx(css.cta)} {...cta} />}
    </div>
  )
}

PushImageCover.defaultProps = {
  titleSize: GlobalTextSize.Small,
}

export default PushImageCover
