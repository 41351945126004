import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'

import type { VideoProps } from '~/components/Slices/Video'
import type { ImageProps, Sizes } from '~/components/UI/Image'
import Image from '~/components/UI/Image'
import ResponsiveVideo, {
  ResponsiveVideoProps,
} from '~/components/UI/ResponsiveVideo'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = {
  BIG: [{ breakpoints: 'md', ratio: 10 / 24 }, { ratio: 16 / 24 }],
  SMALL: [{ breakpoints: 'md', ratio: 6 / 24 }, { ratio: 13 / 24 }],
}

export interface EditoDypticImageProps {
  className?: string
  imageSmall?: ImageProps
  videoUrlSmall?: VideoProps['src']
  videoUrlSmallMobile?: VideoProps['src']
  videoSmallRatio?: VideoProps['videoRatio']
  videoSmallRatioMobile?: VideoProps['videoRatio']
  imageBig?: ImageProps
  videoUrlBig?: VideoProps['src']
  videoUrlBigMobile?: VideoProps['src']
  videoBigRatio?: VideoProps['videoRatio']
  videoBigRatioMobile?: VideoProps['videoRatio']
  reversed?: boolean
}

interface ItemProps {
  className?: string
  image?: ImageProps
  desktopVideoURl?: VideoProps['src']
  mobileVideoUrl?: VideoProps['src']
  ratios?: { video?: string; image?: string }
  sizesFromBreakpoints?: Sizes
  priority?: boolean
  desktopVideoRatio?: number
  mobileVideoRatio?: number
}

function Item({
  className,
  desktopVideoURl,
  mobileVideoUrl,
  image,
  sizesFromBreakpoints,
  ratios,
  desktopVideoRatio,
  mobileVideoRatio,
  priority,
}: ItemProps) {
  const commonVideoProps: ResponsiveVideoProps = {
    disableGrid: true,
    hasPlaysInline: true,
    ratio: ratios.video,
  }

  return desktopVideoURl ? (
    <ResponsiveVideo
      className={cx(css.video, className)}
      mockClassName={css.videoMock}
      videoPlayerClassName={css.videoPlayer}
      desktopVideoUrl={desktopVideoURl}
      mobileVideoUrl={mobileVideoUrl}
      sizesFromBreakpoints={sizesFromBreakpoints}
      desktopPoster={image}
      desktopVideoRatio={desktopVideoRatio}
      mobileVideoRatio={mobileVideoRatio}
      {...commonVideoProps}
    />
  ) : (
    image && (
      <Image
        className={cx(css.image, className)}
        ratio={ratios.image}
        sizesFromBreakpoints={sizesFromBreakpoints}
        layout="fill"
        objectFit="cover"
        asPlaceholder
        priority={priority}
        {...image}
      />
    )
  )
}

function EditoDypticImage({
  className,
  imageBig,
  imageSmall,
  videoUrlSmall,
  videoUrlSmallMobile,
  videoUrlBig,
  videoUrlBigMobile,
  videoSmallRatio,
  videoSmallRatioMobile,
  videoBigRatio,
  videoBigRatioMobile,
  reversed,
}: EditoDypticImageProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const { position: slicePosition } = useSliceProvider()
  return (
    <div
      ref={ref}
      className={cx(css.EditoDypticImage, className, gridStyle, { reversed })}>
      <Item
        className={cx(css.item, css.small)}
        desktopVideoURl={videoUrlSmall}
        mobileVideoUrl={videoUrlSmallMobile}
        image={imageSmall}
        sizesFromBreakpoints={IMAGE_SIZES.SMALL}
        ratios={{
          video: css.videoRatio,
          image: css.imageSmallRatio,
        }}
        desktopVideoRatio={videoSmallRatio}
        mobileVideoRatio={videoSmallRatioMobile}
        priority={slicePosition === 1}
      />

      <Item
        className={cx(css.item, css.big)}
        desktopVideoURl={videoUrlBig}
        mobileVideoUrl={videoUrlBigMobile}
        image={imageBig}
        sizesFromBreakpoints={IMAGE_SIZES.BIG}
        ratios={{
          video: css.ratio,
          image: css.imageBigRatio,
        }}
        desktopVideoRatio={videoBigRatio}
        mobileVideoRatio={videoBigRatioMobile}
        priority={slicePosition === 1}
      />
    </div>
  )
}

EditoDypticImage.defaultProps = {
  reversed: false,
}

export default EditoDypticImage
