import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextAlign } from '~/@types/text-align'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title22_30Haffer,
  [GlobalTextSize.Small]: GlobalTextPreset.Title22_16Haffer,
}
export interface HeroOneLinerTextProps {
  className?: string
  title?: RichTextBlocks
  subtitle?: RichTextBlocks
  textAlign?: GlobalTextAlign
  titleSize?: GlobalTextSize
}

function HeroOneLinerText({
  className,
  title,
  subtitle,
  textAlign = GlobalTextAlign.AlignLeft,
  titleSize = GlobalTextSize.Big,
}: HeroOneLinerTextProps) {
  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[titleSize],
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const gridStyle = useStyle({
    grid: GlobalGridPreset.BASE_GRID,
  })

  if (!title) return null

  return (
    <div className={cx(css.HeroOneLinerText, gridStyle, className)}>
      <RichText
        className={cx(css.title, titleStyle, {
          isCentered: textAlign === GlobalTextAlign.AlignCenter,
          isLeft: textAlign === GlobalTextAlign.AlignLeft,
          isRight: textAlign === GlobalTextAlign.AlignRight,
        })}
        render={title}
      />
      <RichText
        className={cx(css.subtitle, subtitleStyle, {
          isCentered: textAlign === GlobalTextAlign.AlignCenter,
          isLeft: textAlign === GlobalTextAlign.AlignLeft,
          isRight: textAlign === GlobalTextAlign.AlignRight,
        })}
        render={subtitle}
      />
    </div>
  )
}

export default HeroOneLinerText
