import classnames from 'classnames/bind'
import { useEffect, useState } from 'react'
import { useInterval } from 'react-use'

import { useIsMountedState } from '@unlikelystudio/react-hooks'

import { useSliceProvider } from '~/providers/SliceProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

declare global {
  interface Window {
    quizKitScriptLoaded: boolean
  }
}

export type ShopifyQuizzProps = {
  className?: string
  quizzId?: string
}

function ShopifyQuizz({ className, quizzId }: ShopifyQuizzProps) {
  const isMounted = useIsMountedState()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (isMounted && quizzId && !isLoaded && !window.quizKitScriptLoaded) {
      const createScript = () => {
        window.quizKitScriptLoaded = true
        const script = document.createElement('script')
        script.defer = true
        script.setAttribute(
          'src',
          'https://d38jc50suw8dg3.cloudfront.net/current/static/bundle.min.js',
        )
        document.head.appendChild(script)
      }

      const createStyles = () => {
        const appStyles = document.createElement('link')
        appStyles.type = 'text/css'
        appStyles.rel = 'stylesheet'
        appStyles.href =
          'https://d38jc50suw8dg3.cloudfront.net/current/static/main.min.css'
        appStyles.media = 'print'
        appStyles.onload = function () {
          appStyles.media = 'all'
        }
        document.head.appendChild(appStyles)
      }

      createScript()
      createStyles()

      setIsLoaded(true)
    }
  }, [isMounted, isLoaded])

  const { position: slicePosition } = useSliceProvider()
  const isFirstSlice = slicePosition ? slicePosition === 1 : false
  const [needCheck, setNeedCheck] = useState(true)

  useInterval(
    () => {
      const containerResult = document.querySelector(
        '#quiz-kit-sections-container',
      )

      if (containerResult) {
        setNeedCheck(false)
      }

      //@ts-ignore
      const links = [...containerResult.querySelectorAll('a')]
      links
        ?.filter(
          (link) =>
            link.href.includes('/products') &&
            link.href.includes('https://api.quizkitapp.com'),
        )
        ?.forEach((link) => {
          const splitted = link.href.split('/products/')
          link.setAttribute(
            'href',
            `https://checkout.ninaricci.com/products/${splitted[1]}`,
          )
        })
    },
    needCheck && isMounted ? 1000 : null,
  )

  return quizzId ? (
    <div
      className={cx(css.ShopifyQuizz, className, {
        isFirstSlice,
      })}>
      <div id="quiz-kit-app-wrapper" data-quiz-kit-key={`${quizzId}`}>
        <div className="quiz-kit-app-line quiz-kit-app-line--long">
          <span className="quiz-kit-app-line-loading quiz-kit-app-line-loading--dark"></span>
        </div>
        <div className="quiz-kit-app-line">
          <span className="quiz-kit-app-line-loading"></span>
        </div>
        <div className="quiz-kit-app-boxes">
          <div className="quiz-kit-app-box">
            <div className="quiz-kit-app-line quiz-kit-app-line--tall">
              <span className="quiz-kit-app-line-loading"></span>
            </div>
            <div className="quiz-kit-app-line quiz-kit-app-line--dark">
              <span className="quiz-kit-app-line-loading quiz-kit-app-line-loading--dark"></span>
            </div>
            <div className="quiz-kit-app-line quiz-kit-app-line--small">
              <span className="quiz-kit-app-line-loading"></span>
            </div>
          </div>
          <div className="quiz-kit-app-box">
            <div className="quiz-kit-app-line quiz-kit-app-line--tall">
              <span className="quiz-kit-app-line-loading"></span>
            </div>
            <div className="quiz-kit-app-line quiz-kit-app-line--dark">
              <span className="quiz-kit-app-line-loading quiz-kit-app-line-loading--dark"></span>
            </div>
            <div className="quiz-kit-app-line quiz-kit-app-line--small">
              <span className="quiz-kit-app-line-loading"></span>
            </div>
          </div>
          <div className="quiz-kit-app-box">
            <div className="quiz-kit-app-line quiz-kit-app-line--tall">
              <span className="quiz-kit-app-line-loading"></span>
            </div>
            <div className="quiz-kit-app-line quiz-kit-app-line--dark">
              <span className="quiz-kit-app-line-loading quiz-kit-app-line-loading--dark"></span>
            </div>
            <div className="quiz-kit-app-line quiz-kit-app-line--small">
              <span className="quiz-kit-app-line-loading"></span>
            </div>
          </div>
          <div className="quiz-kit-app-box">
            <div className="quiz-kit-app-line quiz-kit-app-line--tall">
              <span className="quiz-kit-app-line-loading"></span>
            </div>
            <div className="quiz-kit-app-line quiz-kit-app-line--dark">
              <span className="quiz-kit-app-line-loading quiz-kit-app-line-loading--dark"></span>
            </div>
            <div className="quiz-kit-app-line quiz-kit-app-line--small">
              <span className="quiz-kit-app-line-loading"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default ShopifyQuizz
