import classnames from 'classnames/bind'
import { useRef } from 'react'
import { PropsWithClassName } from '~/@types/generic'
import { GlobalGridPreset } from '~/@types/grid-preset'

import Slider from '@unlikelystudio/react-slider'

import EditoItemPush, {
  EditoPushItemProps,
} from '~/components/Slices/EditoPush/EditoPushItem'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type EditoPushProps = PropsWithClassName<{
  items: EditoPushItemProps[]
}>

function EditoPush({ className, items }: EditoPushProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const { position: slicePosition } = useSliceProvider()
  const customSliderRef = useRef()

  const itemsLength = items?.length

  if (!items || itemsLength === 1) return null
  return (
    <section className={cx(className, css.EditoPush, gridStyle)}>
      <div className={css.wrapper}>
        <Slider
          className={css.mobileSlider}
          containerClassName={css.sliderContainer}
          customSliderRef={customSliderRef}
          snap>
          {items?.map((item, index) => {
            return (
              <EditoItemPush
                key={`edito-item-push-${index}`}
                className={cx(css.item)}
                editoType={itemsLength - 1}
                {...item}
                priority={slicePosition === 1}
              />
            )
          })}
        </Slider>
        <div className={css.desktopList}>
          {items?.map((item, index) => {
            return (
              <EditoItemPush
                key={`edito-item-push-${index}`}
                className={cx(css.item)}
                editoType={itemsLength - 1}
                {...item}
                priority={slicePosition === 1}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default EditoPush
