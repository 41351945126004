import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import {
  FullScreenImage as FullScreenImageAbstract,
  FullScreenImageProps as FullScreenImageAbstractProps,
} from '@unlikelystudio/react-abstract-components'

import Image, { ImageProps } from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import { GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface GridLookBookFullScreenImageProps
  extends FullScreenImageAbstractProps {
  imageProps?: ImageProps
}

function GridLookBookFullScreenImage({
  className,
  imageProps,
  ...props
}: GridLookBookFullScreenImageProps) {
  const t = useTranslate()

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return imageProps ? (
    <FullScreenImageAbstract
      classes={{
        FullScreenImage: cx(className, css.FullScreenImage),
        controlsContainer: cx(css.controlsContainer, gridStyle),
        controls: css.controls,
        fullScreenImageContainer: css.fullScreenImageContainer,
        placeholder: css.placeholder,
        textStyle,
      }}
      imageWidth={imageProps.width}
      imageHeight={imageProps.height}
      image={
        <Image
          layout="fill"
          className={css.placeholder}
          asPlaceholder
          {...imageProps}
        />
      }
      previousComponent={t(GENERAL.FULLSCREEN_PREVIOUS)}
      nextComponent={t(GENERAL.FULLSCREEN_NEXT)}
      closeComponent={t(GENERAL.FULLSCREEN_CLOSE)}
      {...props}
    />
  ) : null
}

GridLookBookFullScreenImage.defaultProps = {}

export default GridLookBookFullScreenImage
