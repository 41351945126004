import classnames from 'classnames/bind'
import { useCallback, useRef } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalOrientation } from '~/@types/orientation'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import Slider from '@unlikelystudio/react-slider'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import ArticleCard, { ArticleCardProps } from '~/components/Slices/ArticleCard'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import getCSSThemeClassName from '~/utils/get-css-theme-classname'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_RATIOS = {
  [GlobalOrientation.PORTRAIT]: css.portraitRatio,
  [GlobalOrientation.LANDSCAPE]: css.landscapeRatio,
}

const IMAGE_SIZES = {
  [GlobalOrientation.PORTRAIT]: [
    { breakpoint: 'md', ratio: 5 / 24 },
    { ratio: 12 / 24 },
  ],
  [GlobalOrientation.LANDSCAPE]: [
    { breakpoint: 'md', ratio: 7.5 / 24 },
    { ratio: 18 / 24 },
  ],
}

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title22Haffer,
  [GlobalTextSize.Small]: GlobalTextPreset.Title16_18Haffer,
}

type StoriesSliderItemOrientation =
  | GlobalOrientation.PORTRAIT
  | GlobalOrientation.LANDSCAPE

interface StoriesSliderItemProps extends ArticleCardProps {
  orientation: StoriesSliderItemOrientation
}

export interface StoriesSliderProps {
  className?: string
  title?: RichTextBlocks
  items?: StoriesSliderItemProps[]
  titleSize?: GlobalTextSize
}

function StoriesSlider({
  className,
  title,
  items,
  titleSize,
}: StoriesSliderProps) {
  const { ref } = useTrackingPromotionImpression()
  const customSliderRef = useRef()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[titleSize],
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const titleSizeStyle = getCSSThemeClassName(css, 'titleSize', titleSize)

  const setRef = useCallback(
    (node) => {
      ref?.(node)
      customSliderRef.current = node
    },
    [ref, customSliderRef],
  )

  const { position: slicePosition } = useSliceProvider()

  return (
    <div
      ref={setRef}
      className={cx(css.StoriesSlider, className, titleSizeStyle)}>
      <div className={cx(css.container, gridStyle)}>
        <RichText className={cx(css.title, titleStyle)} render={title} />
      </div>
      <div className={css.containerSlider}>
        {items && items.length > 0 && (
          <Slider
            className={css.slider}
            customSliderRef={customSliderRef}
            infinite={true}
            autoPlay={{ speed: -0.4 }}
            autoIsSlider={false}>
            {items?.map((card, index) => (
              <ArticleCard
                className={cx(css.card, css?.[card.orientation])}
                key={`item_${index}`}
                ratio={IMAGE_RATIOS[card.orientation]}
                sizesFromBreakpoints={IMAGE_SIZES[card.orientation]}
                namePreset={GlobalTextPreset.Title12_14Haffer}
                priority={slicePosition === 1 && index < 5}
                {...card}
              />
            ))}
          </Slider>
        )}
      </div>
    </div>
  )
}

StoriesSlider.defaultProps = {
  titleSize: GlobalTextSize.Big,
}

export default StoriesSlider
