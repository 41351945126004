import classnames from 'classnames/bind'
import { CardProduct } from '~/@types/card'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextAlign } from '~/@types/text-align'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import Ratio from '~/components/Abstracts/Ratio'
import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import ShopifyRichText from '~/components/Abstracts/ShopifyRichText'
import VideoComponentForwarded from '~/components/Abstracts/Video'
import Image, { ImageProps } from '~/components/UI/Image'
import InlineCta from '~/components/UI/InlineCta'
import Link from '~/components/UI/Link'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import { isRTFilled } from '~/utils/check-empty-string'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = {
  BIG: [{ breakpoint: 'md', ratio: 1 }, { ratio: 22 / 24 }],
  SMALL: [{ breakpoint: 'md', ratio: 1 }, { ratio: 12 / 24 }],
}

export interface PushProductProps {
  className?: string
  image?: ImageProps
  description?: RichTextBlocks
  card?: CardProduct
  ctaLabel?: string
  reversed?: boolean
  textAlign?: GlobalTextAlign
}

function PushProduct({
  className,
  image,
  description,
  card,
  reversed,
  ctaLabel,
  textAlign,
}: PushProductProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const nameStyle = useStyle({
    textPreset: GlobalTextPreset.Title14Haffer,
    color: GlobalThemeColors.Black,
    textStyling: [GlobalTextStyling.UpperCase, textAlign],
  })
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Label11Haffer,
    color: GlobalThemeColors.DoveGray,
    textStyling: [GlobalTextStyling.UpperCase, textAlign],
  })
  const descriptionStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.MineShaft,
    textStyling: textAlign,
  })

  const isContent =
    card?.name ||
    card?.label ||
    isRTFilled(description) ||
    card?.link ||
    card?.image

  const { position: slicePosition } = useSliceProvider()

  return (
    <div
      ref={ref}
      className={cx(css.PushProduct, className, gridStyle, { reversed })}>
      {image && (
        <Image
          className={cx(css.image)}
          ratio={css.ratio}
          sizesFromBreakpoints={IMAGE_SIZES.BIG}
          layout="fill"
          objectFit="cover"
          asPlaceholder
          priority={slicePosition === 1}
          {...image}
        />
      )}

      {isContent && (
        <div className={cx(css.content)}>
          {card?.image && (
            <Link className={cx(css.linkImage)} {...card?.link}>
              <div className={css.item}>
                {card?.image?.image && (
                  <Image
                    className={cx(css.imageItem)}
                    ratio={css.ratio}
                    sizesFromBreakpoints={IMAGE_SIZES.SMALL}
                    layout="fill"
                    objectFit="cover"
                    asPlaceholder
                    priority={slicePosition === 1}
                    {...card.image.image}
                  />
                )}
                {card?.image?.video && (
                  <Ratio className={css.ratio}>
                    <VideoComponentForwarded
                      {...card.image.video}
                      className={cx(css.video)}
                      autoPlay
                      muted
                      loop
                      playsInline
                    />
                  </Ratio>
                )}
              </div>
            </Link>
          )}

          {card?.name && (
            <h3 className={cx(css.name, nameStyle)}>{card.name}</h3>
          )}
          <ShopifyRichText
            className={cx(css.label, labelStyle)}
            render={card?.label}
          />
          <RichText
            className={cx(css.description, descriptionStyle)}
            render={description}
          />

          {ctaLabel && card?.link && (
            <InlineCta
              textPreset={GlobalTextPreset.Cta12Haffer}
              textStyling={GlobalTextStyling.UpperCase}
              className={cx(css.link)}
              {...card.link}>
              {ctaLabel}
            </InlineCta>
          )}
        </div>
      )}
    </div>
  )
}

PushProduct.defaultProps = {
  reversed: false,
  textAlign: GlobalTextAlign.AlignCenter,
}

export default PushProduct
