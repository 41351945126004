import classnames from 'classnames/bind'
import { useTransition } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextAlign } from '~/@types/text-align'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import InlineCta from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'
import useTruncatedString from '~/hooks/useTruncatedString'

import { isRTFilled } from '~/utils/check-empty-string'

import { GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface WysiwygProps {
  className?: string
  title?: RichTextBlocks
  text?: RichTextBlocks
  textAlign?: GlobalTextAlign
}

function Wysiwyg({ className, title, text, textAlign }: WysiwygProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title22Haffer,
    color: GlobalThemeColors.Black,
    textStyling: [GlobalTextStyling.UpperCase, textAlign],
  })

  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.DoveGray,
    textStyling: [textAlign, GlobalTextStyling.UpperCase],
  })

  const {
    needTruncate,
    text: processedText,
    expanded,
    setExpanded,
  } = useTruncatedString(text)

  const t = useTranslate()
  const [isPending, startTransition] = useTransition()

  return (
    <div ref={ref} className={cx(css.Wysiwyg, className, gridStyle)}>
      <RichText className={cx(css.title, titleStyle)} render={title} />

      {isRTFilled(text) && (
        <div className={cx(css.text, textStyle)}>
          {!needTruncate && (
            <RichText
              ctaPreset={GlobalTextPreset.Cta12Haffer}
              uppercaseLink={false}
              render={processedText}
            />
          )}
          {needTruncate && (
            <>
              {expanded && (
                <RichText
                  ctaPreset={GlobalTextPreset.Cta12Haffer}
                  uppercaseLink={false}
                  render={processedText}
                />
              )}
              {!expanded && (
                <>
                  <div>{processedText}</div>
                  <InlineCta
                    textPreset={GlobalTextPreset.Cta12Haffer}
                    textStyling={GlobalTextStyling.UpperCase}
                    className={css.discoverMore}
                    href={null}
                    isLoading={isPending}
                    onClick={() => {
                      startTransition(() => {
                        setExpanded(true)
                      })
                    }}>
                    {t(GENERAL.DISCOVER_MORE)}
                  </InlineCta>
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

Wysiwyg.defaultProps = {
  textAlign: GlobalTextAlign.AlignCenter,
}

export default Wysiwyg
