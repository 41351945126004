import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useEffect, useMemo } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import Ratio from '~/components/Abstracts/Ratio'
import ShopifyRichText from '~/components/Abstracts/ShopifyRichText'
import VideoComponentForwarded from '~/components/Abstracts/Video'
import { ProductCardProps } from '~/components/Slices/ProductCard'
import { SliderNavigation as NavigationSlider } from '~/components/Slices/ProductsSlider'
import SliderNavigation from '~/components/Slices/PushProductsSlider/SliderNavigation'
import Image, { ImageProps } from '~/components/UI/Image'
import ImageDesktopMobile from '~/components/UI/ImageDesktopMobile'
import InlineCta from '~/components/UI/InlineCta'
import ResponsiveVideo, {
  ResponsiveVideoProps,
} from '~/components/UI/ResponsiveVideo'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import { GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = {
  BIG: [{ ratio: 12 / 24 }],
  SMALL: [{ breakpoint: 'md', ratio: 4 / 24 }, { ratio: 12 / 24 }],
}

export interface PushProductsSliderProps {
  className?: string
  image?: ImageProps
  mobileImage?: ImageProps
  items?: ProductCardProps[]
  backgroundColor?: string
  reversed?: boolean
  desktopVideoUrl?: string
  mobileVideoUrl?: string
  desktopVideoRatio?: number
  mobileVideoRatio?: number
}

function PushProductsSlider({
  className,
  image,
  mobileImage,
  items,
  backgroundColor,
  reversed,
  desktopVideoUrl,
  mobileVideoUrl,
  desktopVideoRatio,
  mobileVideoRatio,
}: PushProductsSliderProps) {
  const { ref } = useTrackingPromotionImpression()
  const t = useTranslate()
  const [
    {
      enableDrag: enableProductSliderDrag,
      prevSlide: prevProductSlide,
      nextSlide: nextProductSlide,
      slideIndex: productSlideIndex,
    },
    setProductSliderState,
  ] = useSliderState()

  const [{ setSlideIndex: setNameSlideIndex }, setNameSliderState] =
    useSliderState()

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const nameStyle = useStyle({
    textPreset: GlobalTextPreset.Title14Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.MineShaft,
  })

  const backgroundColorStyle = backgroundColor ? { backgroundColor } : {}

  const { isPrevVisible, isNextVisible } = useMemo(
    () => ({
      isPrevVisible: enableProductSliderDrag && productSlideIndex !== 0,
      isNextVisible:
        enableProductSliderDrag && productSlideIndex < items?.length - 1,
    }),
    [enableProductSliderDrag, productSlideIndex, items?.length],
  )

  useEffect(() => {
    setNameSlideIndex(productSlideIndex)
  }, [productSlideIndex, setNameSlideIndex])

  const variants = {
    show: { opacity: 1, transition: { duration: 0.35, delay: 0.2 } },
    hide: { opacity: 0, transition: { duration: 0.35, delay: 0 } },
  }

  const { position: slicePosition } = useSliceProvider()

  const commonVideoProps: ResponsiveVideoProps = {
    isAutoplay: true,
    controls: false,
    disableControls: true,
    hasPlaysInline: true,
    isConstrained: true,
    isMuted: true,
    disableGrid: true,
    loop: true,
  }
  if (items?.length === 0) {
    return null
  }

  return (
    <div
      ref={ref}
      className={cx(css.PushProductsSlider, className, gridStyle)}
      style={backgroundColorStyle}>
      {desktopVideoUrl && (
        <ResponsiveVideo
          className={cx(css.image, {
            reversed,
          })}
          desktopVideoUrl={desktopVideoUrl}
          mobileVideoUrl={mobileVideoUrl}
          desktopVideoRatio={desktopVideoRatio}
          mobileVideoRatio={mobileVideoRatio}
          desktopPoster={image}
          mobilePoster={mobileImage}
          sizesFromBreakpoints={IMAGE_SIZES.BIG}
          ratio={css.bigRatio}
          {...commonVideoProps}
        />
      )}

      {!desktopVideoUrl && image && (
        <ImageDesktopMobile
          className={cx(css.image, {
            reversed,
          })}
          desktopImage={image}
          mobileImage={mobileImage}
          sizesFromBreakpoints={IMAGE_SIZES.BIG}
          asPlaceholder
          layout="fill"
          objectFit="cover"
          ratio={css.bigRatio}
          priority={slicePosition === 1}
        />
      )}

      <div
        className={cx(css.content, {
          reversed,
        })}>
        <div className={css.contentImage}>
          <NavigationSlider
            className={cx(css.navigationArrows)}
            onPrev={prevProductSlide}
            onNext={nextProductSlide}
            isPrevVisible={isPrevVisible}
            isNextVisible={isNextVisible}
            hasSeparator={false}
          />
          <Slider
            className={css.productSlider}
            containerClassName={css.containerSlider}
            snap={true}
            setSliderState={setProductSliderState}>
            {items?.map(({ image }, index) => {
              return (
                <div className={css.item}>
                  {image?.image ? (
                    <Image
                      key={`${image?.image?.src}_${index}`}
                      className={cx(css.productImage)}
                      ratio={css.smallRatio}
                      sizesFromBreakpoints={IMAGE_SIZES.SMALL}
                      layout="fill"
                      objectFit="cover"
                      priority={slicePosition === 1 && index === 0}
                      asPlaceholder
                      {...image?.image}
                    />
                  ) : (
                    <Ratio className={css.smallRatio}>
                      <VideoComponentForwarded
                        {...image?.video}
                        className={cx(css.video)}
                        autoPlay
                        muted
                        loop
                        playsInline
                      />
                    </Ratio>
                  )}
                </div>
              )
            })}
          </Slider>
        </div>

        <SliderNavigation
          className={cx(css.navigation)}
          onPrev={prevProductSlide}
          onNext={nextProductSlide}
          isPrevVisible={isPrevVisible}
          isNextVisible={isNextVisible}
          index={productSlideIndex + 1}
          maxIndex={items?.length}
          disableArrows={true}
        />

        <Slider
          className={css.nameSlider}
          snap={true}
          dragProps={{ enable: false }}
          setSliderState={setNameSliderState}>
          {items?.map(({ name, label }, index) => (
            <span key={`${name}_${index}`}>
              {name && (
                <h3 className={cx(css.nameRichtext, nameStyle)}>{name}</h3>
              )}
              <ShopifyRichText
                className={cx(css.text, textStyle)}
                render={label}
              />
            </span>
          ))}
        </Slider>

        <div className={css.buttons}>
          <AnimatePresence>
            {items?.map((item, index) => {
              if (!item?.link) {
                return null
              }

              return (
                <m.div
                  className={cx(css.button, {
                    isActive: index === productSlideIndex,
                  })}
                  key={`item_${index}`}
                  initial={'hide'}
                  animate={index === productSlideIndex ? 'show' : 'hide'}
                  variants={variants}>
                  <InlineCta
                    textStyling={GlobalTextStyling.UpperCase}
                    textPreset={GlobalTextPreset.Cta12Haffer}
                    {...items?.[productSlideIndex]?.link}>
                    {t(GENERAL.DISCOVER)}
                  </InlineCta>
                </m.div>
              )
            })}
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}

PushProductsSlider.defaultProps = {}

export default PushProductsSlider
