import classnames from 'classnames/bind'
import { m, useSpring } from 'framer-motion'
import { GlobalBreakpoints } from '~/@types/breakpoints'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalPosition } from '~/@types/position'
import { GlobalTextPreset } from '~/@types/text-preset'

import Image, { ImageProps } from '~/components/UI/Image'
import TextHorizontalScrolling from '~/components/UI/TextHorizontalScrolling'

import { useSliceProvider } from '~/providers/SliceProvider'

import useBreakpoint from '~/hooks/useBreakpoint'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ImmersiveEditoHeroTeasingProps {
  className?: string
  repeatSmallText?: string
  repeatBigText?: string
  image?: ImageProps
  imageMobile?: ImageProps
  theme?: GlobalThemeColors.Black | GlobalThemeColors.White
  position?: GlobalPosition.TOP | GlobalPosition.BOTTOM
}

function ImmersiveEditoHeroTeasing({
  className,
  repeatSmallText,
  repeatBigText,
  image,
  imageMobile,
  theme,
  position,
}: ImmersiveEditoHeroTeasingProps) {
  // Getting the slice position to set the correct priority
  const { position: slicePosition } = useSliceProvider()

  const isMobile = useBreakpoint(GlobalBreakpoints.MD)

  const transformY = useSpring(0, {
    damping: 150,
    stiffness: 2000,
  })

  return (
    <section
      className={cx(css.ImmersiveEditoHeroTeasing, className, {
        isBottom: position === GlobalPosition.BOTTOM,
      })}>
      <div className={css.imageContainer}>
        {image && (
          <div className={cx(css.imageContent, css.hideOnSmallScreen)}>
            <m.div
              className={css.imageContentAnimated}
              initial={{ scale: 1.05, opacity: 0 }}
              animate={{
                scale: 1.0,
                opacity: 1,
                transition: {
                  delay: 0.2,
                  duration: 1.25,
                  ease: [0.345, 0.45, 0.39, 0.94],
                },
              }}>
              <Image
                className={cx(css.image)}
                layout="fill"
                objectFit="cover"
                asPlaceholder
                priority={slicePosition ? slicePosition === 1 : false}
                {...image}
              />
            </m.div>
          </div>
        )}

        {imageMobile && (
          <div className={cx(css.imageContent, css.hideOnLargeScreen)}>
            <m.div
              className={css.imageContentAnimated}
              initial={{ scale: 1.05, opacity: 0 }}
              animate={{
                scale: 1.0,
                opacity: 1,
                transition: {
                  delay: 0.2,
                  duration: 1.25,
                  ease: [0.345, 0.45, 0.39, 0.94],
                },
              }}>
              <Image
                className={cx(css.image)}
                layout="fill"
                objectFit="cover"
                asPlaceholder
                priority={slicePosition ? slicePosition === 1 : false}
                {...imageMobile}
              />
            </m.div>
          </div>
        )}

        <m.div
          className={css.repeatTextsContainer}
          style={{ y: isMobile ? 0 : transformY }}>
          <div className={css.repeatTexts}>
            {repeatSmallText && (
              <TextHorizontalScrolling
                className={css.repeatText}
                itemClassName={css.repeatSmallItem}
                text={repeatSmallText}
                theme={theme}
                preset={GlobalTextPreset.Title11Haffer}
                speed={0.3}
              />
            )}

            {repeatBigText && (
              <TextHorizontalScrolling
                className={css.repeatText}
                text={repeatBigText}
                theme={theme}
                preset={GlobalTextPreset.Title16Haffer}
                speed={0.7}
              />
            )}
          </div>
        </m.div>
      </div>
    </section>
  )
}

export default ImmersiveEditoHeroTeasing
