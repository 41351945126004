import classnames from 'classnames'
import React from 'react'

import {
  SliderRangeSteps as AbstractSliderRangeSteps,
  SliderRangeStepsProps,
} from '@unlikelystudio/react-abstract-components'

import css from './styles.module.scss'

function SliderRangeSteps({ className, ...rest }: SliderRangeStepsProps) {
  return (
    <AbstractSliderRangeSteps
      className={classnames(css.SliderRangeSteps, className)}
      draggingClassName={css.isDragging}
      notDraggingClassName={css.notDragging}
      cursorContainerClassName={css.cursorContainer}
      cursorClassName={css.cursor}
      {...rest}
    />
  )
}

export default SliderRangeSteps
