import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextAlign } from '~/@types/text-align'
import { GlobalTextPreset } from '~/@types/text-preset'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import Image, { ImageProps } from '~/components/UI/Image'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import { isRTFilled } from '~/utils/check-empty-string'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = {
  LARGE: [{ breakpoint: 'md', ratio: 8 / 24 }, { ratio: 15 / 24 }],
  SMALL: [{ breakpoint: 'md', ratio: 6 / 24 }, { ratio: 12 / 24 }],
}

export interface MasonryImageEditoProps {
  className?: string
  imageSmall?: ImageProps
  imageLarge?: ImageProps
  title?: RichTextBlocks
  text?: RichTextBlocks
  reversed?: boolean
  textAlign?: GlobalTextAlign
}

function MasonryImageEdito({
  className,
  imageLarge,
  imageSmall,
  title,
  text,
  reversed,
  textAlign,
}: MasonryImageEditoProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title14Haffer,
    color: GlobalThemeColors.Black,
    textStyling: textAlign,
  })
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.MineShaft,
    textStyling: textAlign,
  })
  const asOneText = isRTFilled(title) || isRTFilled(text)

  const { position: slicePosition } = useSliceProvider()

  return (
    <div
      ref={ref}
      className={cx(css.MasonryImageEdito, className, gridStyle, { reversed })}>
      {imageLarge && (
        <Image
          className={cx(css.image, css.large)}
          layout="fill"
          objectFit="cover"
          ratio={css.largeRatio}
          sizesFromBreakpoints={IMAGE_SIZES.LARGE}
          asPlaceholder
          priority={slicePosition === 1}
          {...imageLarge}
        />
      )}
      {imageSmall && (
        <Image
          className={cx(css.image, css.small)}
          layout="fill"
          objectFit="cover"
          ratio={css.smallRatio}
          sizesFromBreakpoints={IMAGE_SIZES.SMALL}
          asPlaceholder
          priority={slicePosition === 1}
          {...imageSmall}
        />
      )}

      {asOneText && (
        <div className={css.texts}>
          <RichText className={cx(css.title, titleStyle)} render={title} />
          <RichText className={cx(css.text, textStyle)} render={text} />
        </div>
      )}
    </div>
  )
}

MasonryImageEdito.defaultProps = {
  reversed: false,
  textAlign: GlobalTextAlign.AlignCenter,
}

export default MasonryImageEdito
