import classnames from 'classnames/bind'
import React from 'react'
import { GlobalGridPreset } from '~/@types/grid-preset'

import Image, { ImageProps } from '~/components/UI/Image'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ImmersiveEditoDypticImagesProps {
  className?: string
  leftImage: ImageProps
  rightImage: ImageProps
}

const IMAGE_SIZES = {
  BIG: [{ breakpoints: 'md', ratio: 12 / 24 }, { ratio: 1 }],
  SMALL: [{ breakpoints: 'md', ratio: 12 / 24 }, { ratio: 16 / 24 }],
}

function ImmersiveEditoDypticImages({
  className,
  leftImage,
  rightImage,
}: ImmersiveEditoDypticImagesProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const images = [leftImage, rightImage]

  const { position: slicePosition } = useSliceProvider()

  return (
    <div className={cx(css.ImmersiveEditoDypticImages, className)}>
      <div className={cx(gridStyle)}>
        {images?.map((image, index) => {
          const isLeftImage = index != 0

          return (
            <div
              className={cx(css.imageContainer, { isLeftImage })}
              key={`immersive_edito_image_${index}`}>
              <Image
                className={cx(css.image)}
                ratio={css.ratio}
                sizesFromBreakpoints={
                  IMAGE_SIZES[isLeftImage ? 'SMALL' : 'BIG']
                }
                layout="fill"
                objectFit="cover"
                asPlaceholder
                priority={slicePosition === 1}
                {...image}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ImmersiveEditoDypticImages
