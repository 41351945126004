import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import InlineCta from '~/components/UI/InlineCta'
import { LinkProps } from '~/components/UI/Link'

import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface GridLookbookLinksProps {
  className?: string
  title?: RichTextBlocks
  links?: LinkProps[]
}

function GridLookbookLinks({
  className,
  title,
  links,
}: GridLookbookLinksProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Haffer,
    color: GlobalThemeColors.DoveGray,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div ref={ref} className={cx(css.GridLookbookLinks, className, gridStyle)}>
      <div className={css.content}>
        <RichText className={cx(css.title, titleStyle)} render={title} />

        {links?.length > 0 && (
          <div className={css.links}>
            {links?.map((link, index) => (
              <InlineCta
                textPreset={GlobalTextPreset.Cta12Haffer}
                className={cx(css.link)}
                theme={GlobalThemeColors.Black}
                textStyling={GlobalTextStyling.UpperCase}
                lineProps={{ initialLineState: 'none', thickness: 2 }}
                key={`link_${index}`}
                {...link}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

GridLookbookLinks.defaultProps = {}

export default GridLookbookLinks
