import classnames from 'classnames/bind'
import { m } from 'framer-motion'
import { useRef } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalPosition } from '~/@types/position'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import Slider from '@unlikelystudio/react-slider'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import Image, { ImageProps } from '~/components/UI/Image'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useParallax from '~/hooks/useParallax'
import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import getCSSThemeClassName from '~/utils/get-css-theme-classname'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export enum ITEM_TYPE {
  EVEN = 'even',
  ODD = 'odd',
  BIG = 'big',
}

const IMAGE_RATIOS = {
  [ITEM_TYPE.EVEN]: css.evenRatio,
  [ITEM_TYPE.ODD]: css.oddRatio,
  [ITEM_TYPE.BIG]: css.bigRatio,
}

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 7 / 24 }, { ratio: 14 / 24 }]

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title22_30Haffer,
  [GlobalTextSize.Small]: GlobalTextPreset.Title16_18Haffer,
}

export interface MasonryImageProps {
  className?: string
  titleTop?: RichTextBlocks
  titleBottom?: RichTextBlocks
  images?: ImageProps[]
  titleTopPosition?: GlobalPosition.ABOVE | GlobalPosition.BELOW
  titleSize?: GlobalTextSize
}

function MobileImages({ images }: Pick<MasonryImageProps, 'images'>) {
  const customSliderRef = useRef()

  const { position: slicePosition } = useSliceProvider()

  return images?.length > 0 ? (
    <Slider
      className={cx(css.slider)}
      snap={true}
      infinite={true}
      customSliderRef={customSliderRef}>
      {images?.map((image, index) => {
        const size: keyof typeof IMAGE_RATIOS =
          index % 2 ? ITEM_TYPE.ODD : ITEM_TYPE.EVEN
        return (
          <Image
            key={`${image.src}-${index}`}
            className={cx(css.image)}
            ratio={IMAGE_RATIOS[size]}
            sizesFromBreakpoints={IMAGE_SIZES}
            layout="fill"
            objectFit="cover"
            asPlaceholder
            priority={slicePosition === 1}
            {...image}
          />
        )
      })}
    </Slider>
  ) : null
}

export interface DesktopItemProps extends ImageProps {
  y?: number
  priority?: boolean
}

function DesktopItem({ y, priority, ...rest }: DesktopItemProps) {
  return (
    <m.div className={cx(css.image)} style={{ y }}>
      <Image
        sizesFromBreakpoints={IMAGE_SIZES}
        layout="fill"
        objectFit="cover"
        asPlaceholder
        priority={priority}
        {...rest}
      />
    </m.div>
  )
}

function DesktopImages({ images }: Pick<MasonryImageProps, 'images'>) {
  const chunckImages = [
    [images[0], images[3]],
    [images[1], images[4]],
    [images[2], images[5]],
  ]

  const { y, ref } = useParallax({
    displacement: 75,
  })

  const { position: slicePosition } = useSliceProvider()
  return (
    chunckImages?.length > 0 && (
      <div ref={ref} className={cx(css.imagesContainer)}>
        {chunckImages.map((images, indexChunk) => (
          <div key={indexChunk} className={cx(css.imagesChunk)}>
            {images?.length > 0 &&
              images.map((image, indexImage) => {
                const size: keyof typeof IMAGE_RATIOS =
                  indexChunk === 1 && indexImage === 0
                    ? ITEM_TYPE.BIG
                    : ITEM_TYPE.EVEN

                return image ? (
                  <DesktopItem
                    key={`${image.src}-${indexImage}`}
                    ratio={IMAGE_RATIOS[size]}
                    y={indexChunk === 0 ? y : indexChunk === 2 ? -y : null}
                    priority={slicePosition === 1}
                    {...image}
                  />
                ) : null
              })}
          </div>
        ))}
      </div>
    )
  )
}

function MasonryImage({
  className,
  titleTop,
  titleBottom,
  images,
  titleTopPosition,
  titleSize,
}: MasonryImageProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[titleSize],
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const titleSizeStyle = getCSSThemeClassName(css, 'titleSize', titleSize)

  return (
    <div
      ref={ref}
      className={cx(css.MasonryImage, className, gridStyle, titleSizeStyle)}>
      <RichText
        className={cx(css.title, css.top, titleStyle, {
          above: titleTopPosition === GlobalPosition.ABOVE,
        })}
        render={titleTop}
      />

      {images?.length > 0 && <MobileImages images={images} />}
      {images?.length > 0 && <DesktopImages images={images} />}

      <RichText
        className={cx(css.title, css.bottom, titleStyle)}
        render={titleBottom}
      />
    </div>
  )
}

MasonryImage.defaultProps = {
  titleTopPosition: GlobalPosition.BELOW,
  titleSize: GlobalTextSize.Small,
}

export default MasonryImage
