import useLocale from '~/hooks/useLocale'

import { getParcelLabCountryCode } from '~/utils/locales'

function useParcelLabCountryCode(): string {
  const locale = useLocale()
  return getParcelLabCountryCode(locale) ?? null
}

export default useParcelLabCountryCode
