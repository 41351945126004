import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { ErrorOption } from 'react-hook-form'
import {
  requestNextApiEndpoint,
  SHOPIFY_GIFT_CARD_BALANCE,
} from '~/lib/handle-api'

import useLocale from '~/hooks/useLocale'
import useOnMutationError from '~/hooks/useOnMutationError'

export interface GiftCardData {
  balance: string
  isDisabled: boolean
  cardNumber?: string
  expiresDate?: string | null
}

export interface GiftCardDataResponse {
  isDisabled: boolean
  giftCard: GiftCardData
}

export default function useGiftCardBalanceMutation(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const onMutationError = useOnMutationError(setError)
  const locale = useLocale()

  return useMutation<any, any, any>(
    async ({ giftCardNumber }) => {
      return await requestNextApiEndpoint(SHOPIFY_GIFT_CARD_BALANCE, {
        payload: { giftCardNumber, locale },
      })
    },
    {
      ...mutationOptions,
      onSuccess: (data: GiftCardDataResponse, ...rest) => {
        mutationOptions?.onSuccess(data, ...rest)
      },
      onError: (err, ...rest) => {
        console.log(err)
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}
