import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import { WrapperWithLink } from '@unlikelystudio/react-abstract-components'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import { ImageProps } from '~/components/UI/Image'
import ImageDesktopMobile from '~/components/UI/ImageDesktopMobile'
import { LinkProps } from '~/components/UI/Link'
import ResponsiveVideo, {
  ResponsiveVideoProps,
} from '~/components/UI/ResponsiveVideo'
import SquaredCta, { SquaredCtaProps } from '~/components/UI/SquaredCta'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'

import { isRTFilled } from '~/utils/check-empty-string'
import getCSSThemeClassName from '~/utils/get-css-theme-classname'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = {
  BIG: [{ breakpoint: 'md', ratio: 12 / 24 }, { ratio: 1 }],
  SMALL: [{ breakpoint: 'md', ratio: 4 / 24 }, { ratio: 12 / 24 }],
}

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title22Haffer,
  [GlobalTextSize.Small]: GlobalTextPreset.Title16_18Haffer,
}

export interface PushCoverProps {
  className?: string
  imageBigMobile?: ImageProps
  imageBig?: ImageProps
  imageSmall?: ImageProps
  imageSmallMobile?: ImageProps
  subtitle?: RichTextBlocks
  title?: RichTextBlocks
  backgroundColor?: string
  cta?: SquaredCtaProps
  reversed?: boolean
  titleSize?: GlobalTextSize
  link?: LinkProps
  desktopVideoBigUrl?: string
  mobileVideoBigUrl?: string
  desktopVideoBigRatio?: number
  mobileVideoBigRatio?: number
  desktopVideoSmallUrl?: string
  mobileVideoSmallUrl?: string
  desktopVideoSmallRatio?: number
  mobileVideoSmallRatio?: number
}
function PushCover({
  className,
  imageBig,
  imageBigMobile,
  imageSmall,
  imageSmallMobile,
  subtitle,
  title,
  backgroundColor,
  cta,
  reversed,
  titleSize,
  link,
  desktopVideoBigUrl,
  mobileVideoBigUrl,
  desktopVideoBigRatio,
  mobileVideoBigRatio,
  desktopVideoSmallUrl,
  mobileVideoSmallUrl,
  desktopVideoSmallRatio,
  mobileVideoSmallRatio,
}: PushCoverProps) {
  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[titleSize],
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Label11Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const titleSizeStyle = getCSSThemeClassName(css, 'titleSize', titleSize)

  const backgroundColorStyle = backgroundColor ? { backgroundColor } : {}

  const hasContent =
    imageSmall || isRTFilled(subtitle) || isRTFilled(title) || cta

  const { position: slicePosition } = useSliceProvider()

  const commonVideoProps: ResponsiveVideoProps = {
    isAutoplay: true,
    controls: false,
    disableControls: true,
    hasPlaysInline: true,
    isConstrained: true,
    isMuted: true,
    disableGrid: true,
    loop: true,
  }

  return (
    <div
      ref={ref}
      className={cx(css.PushCover, className, gridStyle, titleSizeStyle, {
        reversed,
      })}
      style={{ ...backgroundColorStyle }}>
      {desktopVideoBigUrl && (
        <ResponsiveVideo
          className={cx(css.bigAsset)}
          desktopVideoUrl={desktopVideoBigUrl}
          mobileVideoUrl={mobileVideoBigUrl}
          desktopVideoRatio={desktopVideoBigRatio}
          mobileVideoRatio={mobileVideoBigRatio}
          desktopPoster={imageBig}
          mobilePoster={imageBigMobile}
          sizesFromBreakpoints={IMAGE_SIZES.BIG}
          ratio={css.bigRatio}
          {...commonVideoProps}
        />
      )}

      {!desktopVideoBigUrl && imageBig && (
        <ImageDesktopMobile
          className={cx(css.bigAsset)}
          desktopImage={imageBig}
          mobileImage={imageBigMobile}
          sizesFromBreakpoints={IMAGE_SIZES.BIG}
          asPlaceholder
          layout="fill"
          objectFit="cover"
          ratio={css.bigRatio}
          priority={slicePosition === 1}
        />
      )}

      {hasContent && (
        <div className={cx(css.content)}>
          <RichText
            className={cx(css.subtitle, subtitleStyle)}
            render={subtitle}
          />
          <RichText className={cx(css.title, titleStyle)} render={title} />

          {(desktopVideoSmallUrl || imageSmall) && (
            <WrapperWithLink {...link}>
              {desktopVideoSmallUrl && (
                <ResponsiveVideo
                  className={cx(css.smallAsset)}
                  desktopVideoUrl={desktopVideoSmallUrl}
                  mobileVideoUrl={mobileVideoSmallUrl}
                  desktopVideoRatio={desktopVideoSmallRatio}
                  mobileVideoRatio={mobileVideoSmallRatio}
                  desktopPoster={imageSmall}
                  mobilePoster={imageSmallMobile}
                  sizesFromBreakpoints={IMAGE_SIZES.SMALL}
                  ratio={css.smallRatio}
                  {...commonVideoProps}
                />
              )}

              {!desktopVideoSmallUrl && imageSmall && (
                <ImageDesktopMobile
                  className={cx(css.smallAsset)}
                  desktopImage={imageSmall}
                  mobileImage={imageSmallMobile}
                  sizesFromBreakpoints={IMAGE_SIZES.SMALL}
                  asPlaceholder
                  layout="fill"
                  objectFit="cover"
                  ratio={css.smallRatio}
                  priority={slicePosition === 1}
                />
              )}
            </WrapperWithLink>
          )}

          {cta && (
            <SquaredCta
              theme={GlobalThemeColors.White}
              className={cx(css.cta)}
              {...cta}
            />
          )}
        </div>
      )}
    </div>
  )
}

PushCover.defaultProps = {
  titleSize: GlobalTextSize.Big,
}

export default PushCover
