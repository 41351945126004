import classnames from 'classnames/bind'

import Image, { ImageProps } from '~/components/UI/Image'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ImageDesktopMobileProps extends Omit<ImageProps, 'src'> {
  imageClassName?: string
  desktopImage: ImageProps
  mobileImage: ImageProps
}

function ImageDesktopMobile({
  className,
  imageClassName,
  desktopImage,
  mobileImage,
  ...props
}: ImageDesktopMobileProps) {
  return (
    <div className={cx(css.ImageDesktopMobile, className)}>
      {desktopImage && (
        <Image
          {...props}
          {...desktopImage}
          className={cx(imageClassName, css.hideOnSmallScreen)}
        />
      )}
      {mobileImage && (
        <Image
          {...props}
          {...mobileImage}
          className={cx(imageClassName, css.hideOnLargeScreen)}
        />
      )}
    </div>
  )
}

export default ImageDesktopMobile
