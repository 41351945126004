import classnames from 'classnames/bind'
import { m } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { PropsWithClassName } from '~/@types/generic'

import LoadingComponent from '~/components/Abstracts/LoadingComponent'
import VideoPlayer, { useVideoState } from '~/components/Abstracts/VideoPlayer'
import WrapperWithLink from '~/components/Abstracts/WrapperWithLink'
import {
  HeroMainMediaImage,
  HeroMainMediaProps,
  HeroMainMediaVideo,
  MediaItemTypes,
} from '~/components/Slices/HeroMain/types'
import { VideoProps } from '~/components/Slices/Video'
import Image, { ImageProps } from '~/components/UI/Image'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ ratio: 1 }]

type ImageItemProps = PropsWithClassName<HeroMainMediaImage> & {
  isPriority?: boolean
}

type VideoItemProps = PropsWithClassName<HeroMainMediaVideo> & {
  isPriority?: boolean
}

function ImageItem({
  className,
  main,
  mobile,
  link,
  isPriority,
}: ImageItemProps) {
  const mobileImage = (mobile as ImageProps) ?? false
  const mainImage = (main as ImageProps) ?? false

  const hasCoverMobile = mobileImage ?? false

  return (
    <WrapperWithLink className={cx(className)} {...link}>
      {mainImage && (
        <Image
          className={cx(css.image, { hasCoverMobile })}
          sizesFromBreakpoints={IMAGE_SIZES}
          layout="fill"
          objectFit="cover"
          asPlaceholder
          priority={isPriority}
          {...mainImage}
        />
      )}

      {hasCoverMobile && mobileImage && (
        <Image
          className={cx(css.image, css.imageMobile)}
          sizesFromBreakpoints={IMAGE_SIZES}
          layout="fill"
          objectFit="cover"
          asPlaceholder
          priority={isPriority}
          {...mobileImage}
        />
      )}
    </WrapperWithLink>
  )
}

function VideoItem({
  className,
  video,
  cover,
  link,
  isPriority,
}: VideoItemProps) {
  const { ref, inView } = useInView({
    threshold: 0,
  })

  const [initialized, setInitialized] = useState(false)

  const [
    {
      videoProgress,
      videoIsReady,
      videoIsLoading,
      videoHandlePausePlay,
      videoPaused,
    },
    setVideoState,
  ] = useVideoState()

  useEffect(() => {
    // initialize video player
    if (inView && !initialized) {
      setInitialized(true)
    }

    // toggle play/pause video when in view
    if (initialized && ((inView && videoPaused) || (!inView && !videoPaused))) {
      videoHandlePausePlay?.()
    }
  }, [inView])

  const hasCover = useMemo(
    () => !videoIsReady && !(videoProgress > 0),
    [videoIsReady, videoProgress],
  )
  const hasLoading = useMemo(
    () => hasCover && videoIsLoading,
    [hasCover, videoIsLoading],
  )

  const videoMain = (video?.main as VideoProps) ?? false
  const videoMobile = (video?.mobile as VideoProps) ?? false
  const videoMainCover = (cover?.main as ImageProps) ?? false
  const videoMobileCover = (cover?.mobile as ImageProps) ?? false

  const hasCoverMobile = videoMobileCover ?? false
  const hasVideoMobile = videoMobile ?? false

  const commonVideoProps = {
    className: css.videoPlayer,
    ratio: css.ratio,
    isAutoplay: true,
    controls: false,
    hasPlaysInline: true,
    setVideoState,
    isConstrained: true,
    isMuted: true,
    loop: true,
  }

  return (
    <div ref={ref} className={cx(className)}>
      <WrapperWithLink className={cx(css.itemLink)} {...link}>
        {videoMain && (
          <m.div
            className={cx(
              css.videoPlayerContainer,
              hasVideoMobile ? css.hideOnSmallScreen : null,
            )}>
            <VideoPlayer {...commonVideoProps} {...videoMain} />
          </m.div>
        )}

        {hasVideoMobile && (
          <m.div
            className={cx(css.videoPlayerContainer, css.hideOnLargeScreen)}>
            <VideoPlayer
              videoRatio={1.77}
              {...commonVideoProps}
              {...videoMobile}
            />
          </m.div>
        )}

        {videoMainCover && (
          <Image
            className={cx(css.image, { hide: !hasCover, hasCoverMobile })}
            sizesFromBreakpoints={IMAGE_SIZES}
            layout="fill"
            objectFit="cover"
            asPlaceholder
            priority={isPriority}
            {...videoMainCover}
          />
        )}

        {videoMobileCover && (
          <Image
            className={cx(css.image, css.imageMobile, { hide: !hasCover })}
            sizesFromBreakpoints={IMAGE_SIZES}
            layout="fill"
            objectFit="cover"
            asPlaceholder
            priority={isPriority}
            {...videoMobileCover}
          />
        )}

        {hasLoading && <LoadingComponent />}
      </WrapperWithLink>
    </div>
  )
}

const mapItemType: {
  [key in MediaItemTypes]: (
    args: ImageItemProps | VideoItemProps,
  ) => JSX.Element
} = {
  ['video']: VideoItem,
  ['image']: ImageItem,
}

type HeroMediaProps = PropsWithClassName<HeroMainMediaProps> & {
  hasSlicePriority: boolean
  type?: MediaItemTypes
}

export function HeroMedia({
  className,
  type,
  hasSlicePriority,
  ...rest
}: HeroMediaProps) {
  const Item = mapItemType?.[type]

  return rest ? (
    <div
      className={cx(css.HeroMedia, className, { isImage: type === 'image' })}>
      {Item ? (
        <Item
          className={cx(css.item, css?.[type])}
          isPriority={hasSlicePriority}
          {...rest}
        />
      ) : null}
    </div>
  ) : null
}
